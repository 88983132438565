<template>
  <v-snackbar
    :color="snackbarStatus"
    v-model="snackbar"
    :timeout="snackBarDuration"
    :top="true"
  >
    <v-row align="center" no-gutters>
      <v-col class="grow">{{snackbarMessage}}</v-col>
      <v-col class="shrink">
        <v-btn
          v-if="snackBarPath !== '' && snackBarPathLabel !== ''"
          color="white"
          outlined
          text
          @click="typeof snackBarPathAction !== 'function' ? $changeRoute(snackBarPath) : snackBarPathAction()"
        >
          {{snackBarPathLabel}}
        </v-btn>
        <v-btn
          color="white"
          outlined
          text
          @click="snackbar = false"
          class="selenium-success-snackbar"
        >
          Close
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: 'QueueSnackBar',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      /**
       * snack bar
       */
      snackbar: false,
      snackbarMessage: '',
      snackbarStatus: 'success',
      snackBarPath: '',
      snackBarPathLabel: '',
      snackBarPathAction: null,
      /**
       * queuing
       */
      snackBarQueue: [],
      snackBarQueueTimeOut: null,
      snackBarDuration: 10000,
      snackBarLocked: false
    }
  },
  methods: {
    addToSnackBarQueue (message, status = 'success', path = '', pathLabel = '', pathAction = null) {
      this.snackBarQueue.push({
        message: message,
        status: status,
        path: path,
        pathLabel: pathLabel,
        pathAction: pathAction
      })
      if (this.snackBarQueue.length === 1) {
        this.launchTurn()
      }
    },
    launchTurn () {
      if (!this.snackBarLocked && this.snackBarQueue.length >= 1) {
        this.activeSnackBar(
          this.snackBarQueue[0].message,
          this.snackBarQueue[0].status,
          this.snackBarQueue[0].path,
          this.snackBarQueue[0].pathLabel,
          this.snackBarQueue[0].pathAction
        )
        this.snackBarQueue.splice(0, 1)
        this.snackBarLocked = true
        this.snackBarQueueTimeOut = setTimeout(() => {
          this.snackBarLocked = false
          this.launchTurn()
        }, this.snackBarDuration)
      }
    },
    /**
     * @param message : {String}  the message to display in the snackbar
     * @param status : {String}  will define the background color of the snack bar ['success' | 'warning' | 'error']
     * @param path : {String}  if path is different than '', a button with the path to redirect will be displayed in the snackbar
     * @param pathLabel : {String}  the label of the redirection button
     * @param pathAction : {Function} if not null, the button will not just change route but execute the function given here
     */
    activeSnackBar (message, status = 'success', path = '', pathLabel = '', pathAction = null) {
      this.snackbar = false
      this.$nextTick(() => {
        this.snackbarMessage = message
        this.snackbarStatus = status
        this.snackbar = true
        this.snackBarPath = path
        this.snackBarPathLabel = pathLabel
        this.snackBarPathAction = pathAction
      })
    }
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler: function () {
        let items = [...this.items]
        for (let i in items) {
          this.addToSnackBarQueue(
            items[i].message,
            items[i].status,
            items[i].path,
            items[i].pathLabel,
            items[i].pathAction
          )
          this.items.splice(this.items.indexOf(items), 1)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
