import AlertModel from './AlertModel.js'
import { getInfoErrorType } from '../../../config/alertsCode'

/**
 * A group key is a group of alert who have the same client_id, io_id, advertiser_id and from the same dsp
 * All group key in the interface are expired
 */
class GroupKeyModel {
  constructor (data) {
    this.id = data.id
    this.AM = data.am
    this.countAlerts = {}
    this.statusAlerts = {}
    this.alerts = {}
    /**
     * return the name of the function to call for check her status
     * @type {{help: string, attributed: string, false_positive: string, done: string}}
     */
    this.statusPerFunction = {
      'attributed': 'isAttributed',
      'help': 'isHelp',
      'false_positive': 'isFalsePositive',
      'not_attributed': 'isNotAttributed',
      'fixed': 'isFixed',
      'done': 'isDone',
      'true_positive': 'isTruePositive'
    }

    for (let groupKey of data.group_key_list) {
      // for avoid to store not integrated alerts
      let errorTypeExist = true
      let errorType = groupKey.error_type
      try {
        getInfoErrorType(errorType)
      } catch (ErrorTypeDontExist) {
        console.warn(ErrorTypeDontExist.message)
        errorTypeExist = false
      }
      if (errorTypeExist) {
        this.alerts[errorType] = [new AlertModel(groupKey, data.identity, groupKey.id, data.id, data.latest_snooze[errorType])]
        this.statusAlerts[errorType] = []
        this.countAlerts[errorType] = groupKey.count_error
      }
    }
    /**
     * @type {AlertIdentity}
     */
    this.group_key = data.identity
  }
  /**
   * a group or alert of same error type is considered as attributed when one of these alert is attributed
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorTypeAttributed (errorType) {
    return this.isErrorType('attributed', errorType)
  }
  /**
   * a group or alert of same error type is considered as false_positive when one of these alert is false_positive
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorTypeFalsePositive (errorType) {
    return this.isErrorType('false_positive', errorType)
  }
  /**
   * a group or alert of same error type is considered as not_attributed when one of these alert is not_attributed
   * and NO alerts are attributed or fixed
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorTypeNotAttributed (errorType) {
    return this.isErrorType('not_attributed', errorType) && !this.isErrorType('attributed', errorType) && !this.isErrorType('fixed', errorType)
  }
  /**
   * a group or alert of same error type is considered as fixed when one of these alert is fixed
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorTypeFixed (errorType) {
    return this.isErrorType('fixed', errorType)
  }

  /**
   * a group or alert of same error type is considered as done when one of these alert is done
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorTypeDone (errorType) {
    return this.isErrorType('done', errorType)
  }

  /**
   * a group or alert of same error type is considered as help when one of these alert is help
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorTypeHelp (errorType) {
    return this.isErrorType('help', errorType)
  }

  /**
   * a group or alert of same error type is considered as true_positive when one of these alert is true_positive
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorTypeTruePositive (errorType) {
    return this.isErrorType('true_positive', errorType)
  }

  __checkStatusAlert (status, errorType) {
    if (status === 'not_attributed') {
      return this.statusAlerts[errorType].indexOf('help') !== -1 ||
        this.statusAlerts[errorType].indexOf('false_positive') !== -1
    }
    return this.statusAlerts[errorType].indexOf(status) !== -1
  }

  /**
   * @param status {string}
   * @param errorType {string}
   * @returns {boolean}
   */
  isErrorType (status, errorType) {
    if (status === undefined) {
      return false
    }

    if (!this.isStatusAllowed(status)) {
      throw Error(`The status ${status} is not a allowed status for this function. List of allowed status : ${this.getAllowedStatus().join(', ')}`)
    }

    if (this.__checkStatusAlert(status, errorType)) {
      return true
    }

    if (status === 'done') {
      return this.alerts[errorType][0].isDone()
    }

    if (status === 'fixed') {
      return this.alerts[errorType][0].isFixed()
    }

    let isStatus = false
    for (let y in this.alerts[errorType]) {
      let alert = this.alerts[errorType][y]

      if (alert[this.getFuncStatus(status)]()) {
        isStatus = true
        break
      }
    }
    return isStatus
  }

  /**
   * return true if one of the alert isAttributed to the debugger debuggerName
   * @param errorType
   * @param debuggerName
   * @returns {boolean}
   */
  isErrorTypeAttributedTo (errorType, debuggerName) {
    if (!this.isErrorTypeAttributed(errorType)) {
      return false
    }

    let isAttributedTo = false
    for (let y in this.alerts[errorType]) {
      let alert = this.alerts[errorType][y]

      if (alert.isAttributedTo(debuggerName)) {
        isAttributedTo = true
        break
      }
    }
    return isAttributedTo
  }
  /**
   * return true if the most recent alert of the group_key is set as done or one of the alert is set a false_positive
   */
  isGroupKeyDoneOrFalsePositive () {
    let isDoneOrFalsePositive = true
    for (let errorType in this.alerts) {
      if (!this.isErrorTypeDone(errorType) && !this.isErrorTypeFalsePositive(errorType)) {
        isDoneOrFalsePositive = false
        break
      }
    }

    return isDoneOrFalsePositive
  }

  getFuncStatus (status) {
    if (!this.isStatusAllowed(status)) {
      throw Error(`The status ${status} is not a allowed status for this function. List of allowed status : ${this.getAllowedStatus().join(', ')}`)
    }
    return this.statusPerFunction[status]
  }

  /**
   * return the list of status allowed to being used in 'isErrorType'
   * @returns {string[]}
   */
  getAllowedStatus () {
    return Object.keys(this.statusPerFunction)
  }

  isStatusAllowed (status) {
    return this.getAllowedStatus().indexOf(status) !== -1
  }

  getDspName () {
    if (!this.group_key.dsp) {
      return null
    }
    let dspName = this.group_key.dsp.toLowerCase()
    // special case trueview === youtube
    return dspName !== 'trueview' ? dspName : 'youtube'
  }

  haveGroupKeyValidAlerts () {
    return Object.keys(this.alerts).length !== 0
  }

  haveGroupKeyAlertsOfType (errorType) {
    return Object.keys(this.alerts).indexOf(errorType) !== -1
  }
}

export default GroupKeyModel
