import DspConfig from './DspConfigModel/DspConfig'
import { toMergeCommonCustomProdMgmt, toMergeCommonMarkupModule } from './commonDspConfig'
import { KpiValue } from '../../types/brief_enum'

export default new DspConfig({
  headers: [
    { text: 'info', value: 'group_name', sortable: false, onlyHeader: true },
    { text: 'Group Name', value: 'group_name', class: 'td-data-table-left' },
    { text: 'Member / Advertiser', value: 'advertiser_id', sortable: false, class: 'td-data-table-center' },
    { text: 'IO/LI/CA', value: '', sortable: false, class: 'td-data-table-center' },
    { text: 'Objectives', value: '', sortable: false, class: 'td-data-table-left' },
    { text: 'Constraint_IO', value: '', sortable: false, class: 'td-data-table-left' }
  ],
  client_value: 'partner_id',
  ioField: 'optimize_insertion_orders',
  newIoField: 'insertion_order_id',
  ioFieldLabel: 'Insertion order Id',
  ioFieldAlert: 'insertion_order_id',
  adLabel: 'Advertiser id',
  adField: 'advertiser_id',
  matchingFields: ['matching_li'],
  specialFields: [],
  kpiToOptimizeOptions: [
    KpiValue.CPC,
    KpiValue.CTR,
    KpiValue.VTR,
    KpiValue.CPCV,
    KpiValue.V_CPM,
    KpiValue.CPA,
    KpiValue.CPA_PC,
    KpiValue.CPM,
    KpiValue.CPIAVC,
    KpiValue.authentic_attention_index,
    KpiValue.reach,
    KpiValue.viewable_time,
    KpiValue.ViewTR,
    KpiValue.ROAS,
    KpiValue.LTV
  ],
  constraintsFormConfig: [
    { label: 'Expected margin daily (%)', computed: 'expectedMargin', isPercentage: true, className: 'selenium-margin-daily' },
    { label: 'Highest allowed CPM', computed: 'highestAllowedCPM', isPercentage: false, className: 'selenium-max-cpm' },
    { label: 'Lowest allowed CPM', computed: 'lowestAllowedCPM', isPercentage: false, className: 'selenium-min-cpm' },
    { label: 'Lowest allowed view rate (%)', computed: 'lowestAllowedViewRate', isPercentage: true, className: 'selenium-view-rate' },
    { label: 'KPI Target (DSP)', computed: 'proxyTargetKpiValue', isPercentage: false, isDouble: true, doubleComputed: 'proxyTargetForOptimizedKpi', className: 'selenium-target-kpi' },
    { label: 'KPI Target (3rd party)', computed: 'proxyTargetKpiValue3rdParty', isPercentage: false }
  ],
  overwritingFields: {
    normal: [
      { label: 'Min viewability', value: 'keep_min_viz', reverseBoolean: true },
      // { label: 'Frequency', value: 'keep_frequency', reverseBoolean: true },
      { label: 'Time parting', value: 'keep_timeparting', reverseBoolean: true },
      { label: 'Force pacing ASAP', value: 'force_pacing_asap_li' },
      { label: 'Overwrite daily budget li', value: 'overwrite_daily_budget_li' },
      { label: 'Fold position (only works if SDF version>=5)', value: 'remove_fold_position' },
      { label: 'Overwrite Creative Selection', value: 'overwrite_creative_selection' },
      { label: 'Automatically remove deprecated url', value: 'automatically_remove_deprecated_url' }
    ],
    advanced: [
    ]
  },
  toMerge: [
    { prop: ['partner_id'] },
    { prop: ['advertiser_id'] },
    { prop: ['true_conversion_funnel_pixel_ids'] },
    { prop: ['conversion_funnel_pixel_ids'] },
    { prop: ['true_KPI_to_optimize'] },
    { prop: ['KPI_to_optimize'] },
    { prop: ['constraints_io', 'margin_daily'] },
    { prop: ['constraints_io', 'max_CPM'] },
    { prop: ['constraints_io', 'min_CPM'] },
    { prop: ['constraints_io', 'max_A'] },
    { prop: ['constraints_io', 'mode'] },
    { prop: ['keep_min_viz'] },
    // { prop: ['keep_frequency'] },
    { prop: ['keep_timeparting'] },
    { prop: ['overwrite_creative_selection'] },
    { prop: ['automatically_remove_deprecated_url'] },
    { prop: ['force_pacing_asap_li'] },
    { prop: ['overwrite_daily_budget_li'] },
    { prop: ['remove_fold_position'] },
    { prop: ['opti_auto'] },
    { prop: ['otto_min_viz'] },
    { prop: ['objective', 'true_PV_window'] },
    { prop: ['objective', 'true_PC_window'] },
    { prop: ['objective', 'PV_window'] },
    { prop: ['objective', 'PC_window'] },
    { prop: ['obj_watcher', 'margin', 'value'] },
    { prop: ['obj_watcher', 'max_CPM', 'value'] },
    { prop: ['obj_watcher', 'CPA', 'value'] },
    { prop: ['obj_watcher', 'min_CPM', 'value'] },
    { prop: ['obj_watcher', 'min_viz', 'value'] },
    { prop: ['obj_watcher', 'min_crate', 'value'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'value_3rd_party'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'KPI'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'value_3rd_party'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'KPI'] },
    { prop: ['matching_li'] },
    { prop: ['status'] },
    { prop: ['max_frequency'] },
    { prop: ['overwrite_frequency'] },
    { prop: ['true_conv_measurement_tool'] },
    { prop: ['conv_measurement_tool'] },
    { prop: ['use_opti_ratio_surcouche'] },
    { prop: ['constraints_io', 'force_min_budget'] },
    { prop: ['constraints_io', 'max_cutoff'] },
    { prop: ['use_custom_algo'] },
    { prop: ['revenue_type'] },
    { prop: ['overwrite_unknown_demographic'] },
    ...toMergeCommonMarkupModule,
    ...toMergeCommonCustomProdMgmt,
    { prop: ['strat_lvl'] },
    { prop: ['retry'] },
    { prop: ['fast_retry'] },
    { prop: ['reactivate_otto'] },
    { prop: ['waterfall_priority'] },
    { prop: ['KPI_rolling_period'] },
    { prop: ['true_pivot_variable'] },
    { prop: ['p3_obj_watcher'] },
    { prop: ['p3_obj_watcher_alpha'] },
    { prop: ['p3_obj_watcher_pivot_variables'] }
  ]
})
