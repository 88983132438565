/**
 * module for autologout after a delay of inactivity
 * default delay : 2 Hours
 * @author Quentin Gary
 * @copyright Scibids
 *
 * ex :
 *
 * let autologout = new Autologout()
 *
 * autologout.init(function () {
 *   alert('You have been inactive for 120 minutes. Your session has expired and the application will refresh.')
 *   window.location.reload()
 * })
 */
function Autologout () {
  const MINUTE_IN_MILLISECONDS = 60000
  const HOUR_IN_MILLISECONDS = MINUTE_IN_MILLISECONDS * 60
  const TWO_HOURS = HOUR_IN_MILLISECONDS * 2
  const event = ['resize', 'mousemove', 'click', 'keypress']

  /**
     * @param logoutAction : {callback} the function to execute for autologout
     * @param interval : {Number} time out duration
     */
  this.init = function (logoutAction, interval = null) {
    this.logoutAction = logoutAction
    this.settedInterval = interval !== null ? interval : TWO_HOURS

    for (let i in event) {
      document.addEventListener(event[i], this.resetTimeout.bind(this))
    }
  }

  this.setTimeout = function () {
    this.timeout = setTimeout(() => {
      this.logoutAction()
    }, this.settedInterval)
  }

  this.clearTimeout = function () {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  this.resetTimeout = function () {
    this.clearTimeout()
    this.setTimeout()
  }

  this.destroy = function () {
    this.clearTimeout()
  }
}

export {
  Autologout
}
