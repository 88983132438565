import { apiCaller } from '../store'
import SurcoucheConfig from '../config/SurcoucheConfig'

async function getConfig () {
  let result = await apiCaller.getSurcoucheConfig()
  if (apiCaller.isResponseError(result)) {
    console.warn('Error when calling the config')
    return false
  }
  return result.data
}

export const surcoucheConfig = new SurcoucheConfig(getConfig)
