import { transformIsoDate } from '../../../utils/commonUtils'

class AlertModel {
  constructor (data, groupKey, hashKey, identityId, latestSnooze) {
    this.data = data.alert_data
    this.date = data.last_alert_insertion_date ? data.last_alert_insertion_date : data.insertion_date
    this.error_type = data.error_type
    /**
     * for each status :
     * -name : is the person who have set the status
     * -user : the person which the treatment attributed (attributed) or who have fixed the status
     */
    this.expired = data.expired
    this.done = data.done
    this.done_last_update = data.done_last_update
    this.done_message = data.done_message
    this.false_positive = data.false_positive
    this.false_positive_message = data.false_positive_message
    this.false_positive_last_update = data.false_positive_last_update
    this.help = data.help
    this.help_message = data.help_message
    this.help_last_update = data.help_last_update
    this.attributed = data.attributed
    this.attributed_message = data.attributed_message
    this.attributed_last_update = data.attributed_last_update
    this.fixed = data.fixed
    this.fixed_message = data.fixed_message
    this.fixed_last_update = data.fixed_last_update
    this.group_key = groupKey
    this.settings = data.settings
    this.id = data.id
    this.true_positive = data.true_positive
    this.true_positive_message = data.true_positive_message
    this.true_positive_last_update = data.true_positive_last_update
    /**
     * contain the groupKeyId of the parent groupKey
     */
    this.keyFrom = hashKey
    this.identityId = identityId
    this.latest_snooze = latestSnooze
  }

  hasNoStatus () {
    return !this.isFalsePositive() && !this.isDone() && !this.isHelp() && !this.isFixed() && !this.isAttributed() && !this.isTruePositive()
  }

  isTruePositive () {
    return this.is('true_positive')
  }

  isFalsePositive () {
    return this.is('false_positive') && !this.isTruePositive()
  }

  wasFalsePositive () {
    return this.is('false_positive')
  }

  isDone () {
    return this.is('done')
  }

  isHelp () {
    return this.is('help')
  }

  isFixed () {
    return this.is('fixed')
  }

  isAttributed () {
    return this.is('attributed')
  }

  isNotAttributed () {
    return !this.isAttributed() && !this.isDone() && (this.isHelp() || this.isFalsePositive())
  }

  is (status) {
    return this[status]
  }

  dateToLocal () {
    return transformIsoDate(this.date, true, true, true)
  }

  isAttributedTo (debuggerName) {
    if (!this.isAttributed()) {
      return false
    }
    return this.attributed !== undefined && this.attributed.user === debuggerName
  }

  getStatusList () {
    let statusList = []

    if (this.isDone()) {
      statusList.push('done')
    }

    if (this.isHelp()) {
      statusList.push('help')
    }

    if (this.isFalsePositive()) {
      statusList.push('false_positive')
    }

    if (this.isAttributed()) {
      statusList.push('attributed')
    }

    if (this.isFixed()) {
      statusList.push('fixed')
    }

    return statusList
  }

  getDspName () {
    if (!this.group_key.dsp) {
      return null
    }
    let dspName = this.group_key.dsp.toLowerCase()
    // special case trueview === youtube
    return dspName !== 'trueview' ? dspName : 'youtube'
  }

  getInsertionOrder () {
    return this.group_key.insertion_order_id
  }

  getMessageForStatus (status) {
    return this[status + '_message']
  }

  getMessageByForStatus (status) {
    let message = this.getMessageForStatus(status)
    return message && message.message_by
      ? message.message_by
      : null
  }

  getMessageToForStatus (status) {
    let message = this.getMessageForStatus(status)
    return message && message.message_to
      ? message.message_to
      : null
  }

  getContentForStatus (status) {
    let message = this.getMessageForStatus(status)
    return message && message.content
      ? message.content
      : null
  }
}

export default AlertModel
