import store from '../../store'
import { users } from '../../store/modules/users'
import Component from 'vue-class-component'
import Vue from 'vue'

// load the store module dynamically only when needed
if (!store.state.users) store.registerModule('users', users)

@Component
export class usersRoleMixin extends Vue {
  haveCurrentUserTheRole (role: string) {
    return this.$store.getters.getCurrentUserRoles.indexOf(role) !== -1
  }
  haveUserTheRoles (roles: string[]) {
    if (!roles) {
      return true
    }
    for (let i in roles) {
      if (!this.haveCurrentUserTheRole(roles[i])) {
        return false
      }
    }
    return true
  }
}
