<template>
    <div class="text-center wait-bar-auth">
        <div v-if="message">
            {{message}}
        </div>
        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
  name: 'WaitBar',
  props: ['message'],
  data () {
    return {

    }
  }
}
</script>
<style>
.wait-bar-auth {
    margin-top: 10em !important
}
</style>
