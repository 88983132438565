import { IDspConfig } from '../dspConfigPerDspTypes'

export default class DspConfig implements IDspConfig {
  headers
  // eslint-disable-next-line camelcase
  client_value
  ioField
  newIoField
  ioFieldLabel
  ioFieldAlert
  adLabel
  adField
  matchingFields
  specialFields
  kpiToOptimizeOptions
  constraintsFormConfig
  overwritingFields
  toMerge

  constructor (config: IDspConfig) {
    this.headers = config.headers// headers used in alertings table
    this.client_value = config.client_value // member_id field
    this.ioField = config.ioField // the insertion order field (only ,xxxxx)
    this.newIoField = config.newIoField // // the new insertion order field
    this.ioFieldLabel = config.ioFieldLabel // insertion order label
    this.ioFieldAlert = config.ioFieldAlert // the insertion order field alertings side (work with instructions field strat too)
    this.adLabel = config.adLabel // advertiser_id label
    this.adField = config.adField // advertiser_id fields
    this.matchingFields = config.matchingFields // matching field list (ex appx : ['matching_li', 'matching_cp'])
    this.specialFields = config.specialFields // field not in ioField, adField, client_value, dspOverwritingValue, and dspConfig.common
    this.kpiToOptimizeOptions = config.kpiToOptimizeOptions // list of allowed options in Kpi_To_optimize input IoForm
    this.constraintsFormConfig = config.constraintsFormConfig
    this.overwritingFields = config.overwritingFields // list of DspOverwriting (IoForm) object for generated the on/off button
    this.toMerge = config.toMerge // brief field in common for all the instruction of an insertion order
  }
}
