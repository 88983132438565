import axios from 'axios'
import { getAxiosHeaders } from '../Api/headersHelper'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

// eslint-disable-next-line no-unused-expressions
'use strict'

/**
 * Auth module for vue.js app
 *
 * Created for easily connect a project to the internal security system provided by the Api
 *
 * ex option object => see authConfig.js
 *
 * use :
 * - set the option object
 * - call 'callAuthApi' in beforeEnter method of the vue Router
 * - then, call ApiCallback in the Callback view (in mounted)
 * - call destroyJwt when the user go out for destroy the json web token
 *
 * a simple cookie system is used for avoid to call the auth between each route
 *
 * @author Quentin Gary
 * @copyright Scibids
 */
const AuthModule = function (options, env = 'development') {
  const USER_JWT = 'user_jwt'
  this.$apiCallBackUrl = options.apiCallBackUrl
  this.$callbackPath = options.callbackPath
  this.$baseUrlAuth = options.baseUrlAuth
  this.$mainPagePath = options.mainPagePath
  this.$userId = null
  this.__allowedEnv = ['production', 'development', 'staging', 'sandbox']
  this.maxLifeTimeBeforeRenewJwt = 15 // minutes

  if (this.__allowedEnv.indexOf(env) === -1) {
    throw new Error(`env value must be one of these values : ${this.__allowedEnv.join(', ')}, ${env} received`)
  }

  this.$isAuthentified = false

  this.$oAuthData = {
    state: '',
    code: '',
    scope: '',
    session_state: '',
    authuser: ''
  }

  this.getUserId = function () {
    if (this.$userId !== null && typeof this.$userId === 'number') {
      return Number(this.$userId)
    }
    return this.getInJwt('user_id')
  }

  /**
   * To call in beforeEnter of the router
   */
  this.callAuthApi = function (to, from, next) {
    // try to connected with json web storage
    // if successfull, we stop here
    let jwt = this.getJwt()
    // eslint-disable-next-line yoda
    if (false !== jwt) {
      /**
       * new step, we check if the jwt is close to the end of his life.
       * If yes, we call the api for a new one
       */
      if (this.lifetimeOfTokenIsMoreThan(this.maxLifeTimeBeforeRenewJwt)) {
        next()
        this.$isAuthentified = true
        return true
      }

      this.__apiCheckJwt(jwt).then(data => {
        if (data) {
          next()
          this.$isAuthentified = true
          return true
        }
      })
    } else {
      // otherwise, we send a call to the api for a connexion with oAuth2
      this.__processAuthApi(window.location.href)
    }
  }
  /**
   * Check if the token will expire in less than the given minutes
   * @param minutes {number} - the number of minutes
   * @returns {boolean}
   */
  this.tokenWillExpireIn = function (minutes) {
    return !this.lifetimeOfTokenIsMoreThan(minutes)
  }

  this.tokenWillExpireSoon = function () {
    return !this.lifetimeOfTokenIsMoreThan(this.maxLifeTimeBeforeRenewJwt)
  }

  this.checkIfRefreshIsNeeded = function () {
    if (this.tokenWillExpireSoon()) {
      console.warn('refreshToken')
      this.refreshToken()
    } else {
      console.warn('Token is still valid enough. No need to refresh.')
    }
  }
  this.refreshToken = function () {
    this.__processAuthApi(window.location.href)
  }

  /**
   * To call on the Callback route Vue side
   * call callback Api side for checking the user info
   */
  this.ApiCallback = function (state = null) {
    this.__getRequestArgs()

    let call = this.$apiCallBackUrl + '?'
    // create the call
    for (let key in this.$oAuthData) {
      call += key + '=' + this.$oAuthData[key] + '&'
    }

    call += 'redirect_uri=' + btoa(this.$callbackPath)

    axios.post(call).then((response) => {
      if (response['data']['status'] === 200) {
        let jwt = response['data']['jwt']
        this.__stockJwt(jwt)
        this.$isAuthentified = true

        let urlRedirect = this.$mainPagePath

        if (typeof state === 'string') {
          urlRedirect = state
        }

        if (urlRedirect.endsWith('/Logout')) {
          urlRedirect = this.$mainPagePath
        }
        window.location.assign(urlRedirect)
      }
    }, (error) => {
      console.log(error)
    })
  }

  this.getJwt = function () {
    if (localStorage.getItem(USER_JWT) === undefined || localStorage.getItem(USER_JWT) === null) {
      return false
    }

    return localStorage.getItem(USER_JWT)
  }

  this.destroyJwt = function () {
    localStorage.removeItem(USER_JWT)
  }

  this.getInJwt = function (key) {
    const jwt = this.getJwt()

    if (typeof jwt !== 'string') {
      return null
    }
    const jwtDecoded = jwt_decode(this.getJwt())
    return jwtDecoded[key]
  }

  /**
   * Get the time left before the jwt expire.
   * Has the option to return the time in minutes.
   * Has no security purposes, only for information.
   * @param inMinutes
   * @returns {number}
   */
  this.getLifetimeOfJwt = function (inMinutes = false) {
    const exp = this.getInJwt('exp')
    const date = new Date(exp * 1000)
    const now = new Date()
    const diff = date - now
    const diffInMinutes = diff / 1000 / 60
    return inMinutes ? diffInMinutes : diff
  }

  this.lifetimeOfTokenIsMoreThan = function (minutes) {
    return this.getLifetimeOfJwt(true) > minutes
  }

  /**
   * Call callback route of the Api
   * The Api return (if all is right) the google authorization_url
   * When the authorization_url is received, redirect to her
   */
  this.__processAuthApi = function (state = null) {
    let baseUrlAuth = `${this.$baseUrlAuth}?callback=`
    let callbackPath = this.$callbackPath
    let call = baseUrlAuth + encodeURIComponent(callbackPath)

    if (state !== null) {
      call += `&state=${encodeURIComponent(state)}`
    }

    axios.post(call).then((response) => {
      let authorizationUrl = response.data['data']['authorization_url']
      // the api return to the interface the url of the google OAuth Form
      window.location.replace(authorizationUrl)
    }, (error) => {
      console.log(error)
    })
  }

  /**
   * call the Api
   * the Api return 200 if the jwt is right
   */
  this.__apiCheckJwt = function (jwt) {
    let call = this.$baseUrlAuth
    const headers = getAxiosHeaders(jwt)
    return axios.post(call, null, { headers: headers }).then((response) => {
      /* eslint-disable eqeqeq */
      if (response['data']['status'] == 200) {
        /* eslint-enable eqeqeq */
        return true
      }
    }).catch(error => {
      console.log(error.response)
      // if jwt is expired, he is deleting and we process to a classic auth for having a new jwt
      // eslint-disable-next-line eqeqeq
      if (error.response['data']['status'] == 401 && error.response['data']['errors']['jwt'] !== undefined) {
        console.log('Jwt expired. Classic auth process...')
        this.destroyJwt()
        this.__processAuthApi(window.location.href)
      }
      return false
    })
  }

  this.__getRequestArgs = function () {
    const search = new URLSearchParams(window.location.search)

    this.$oAuthData.state = search.get('state')
    this.$oAuthData.code = search.get('code')
    this.$oAuthData.scope = search.get('scope')
    this.$oAuthData.session_state = search.get('session_state')
    this.$oAuthData.authuser = search.get('authuser')
  }

  this.__stockJwt = function (jwt) {
    localStorage.setItem(USER_JWT, jwt)
  }
}

export default AuthModule
