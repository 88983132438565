/**
 * use this mixin with view who need access to user.
 * load the user vuex store module
 * call the user endpoint of the api and set the current user
 */
import store from '../../store'
import { users } from '../../store/modules/users'
import Component, { mixins } from 'vue-class-component'
import { usersRoleMixin } from '@/mixins/usersRoleMixin'

// load the store module dynamically only when needed
if (!store.state.users) store.registerModule('users', users)

@Component
export class usersMixin extends mixins(usersRoleMixin) {
  async created () {
    if (this.$store.getters.isUserApiLoading) {
      console.warn('User api is already loading, don\'t load it again..')
      return
    }
    await this.loadUserInfo()
    /** plausible (analytics) **/
    const userStatus = this.$store.getters.getUserStatus
    if (userStatus.userId) {
      this.$plausibleHelper.registerPlausibleRole(userStatus)
    } else {
      console.warn('Error when loading the current user. Try another time..')
      await this.loadUserInfo()
    }
  }

  async loadUserInfo () {
    if (this.$authModule.$isAuthentified &&
        this.$store.getters.isUserStatusSet &&
        !this.$store.getters.isUserApiLoading) {
      console.warn('Load user info..')
      this.$store.commit('setApiUsersIsLoading')
      const userId = this.$authModule.getUserId()
      await this.$store.dispatch('getUserInfoFromApi', userId)
      await this.$store.dispatch('getCurrentUserRolesData', userId)
      this.$store.commit('setApiUsersIsLoaded')
    }
  }
}
