import { API_LOADED, API_LOADING, apiCaller, Vue } from '../index'
import UserModel from '../../src/models/Users/UserModel'

const state = {
  /**
   * list of all users
   * @type UserModel[]
   */
  users: [],
  /**
   * current username
   */
  currentUser: '',
  /**
   * current user mail
   */
  currentUserMail: '',
  currentUserDvMail: '',
  /**
   * contain the current user information
   * @type UserStatusState
   */
  userStatus: {
    isDebugger: false,
    isAm: false,
    userId: null,
    isSettupper: false,
    isSale: false,
    isAdminAndFinance: false
  },
  apiStatusUsers: API_LOADED,
  currentUserRoles: [],
  roleByType: {}
}

const actions = {
  async getUsersData (context) {
    let response = await apiCaller.getUsers()

    if (apiCaller.isResponseError(response)) {
      if (apiCaller.isApiResponseStatusUnauthorized(response)) {
        context.commit('setAskRelog', true)
      }
      return response
    }
    context.commit('setUsers', response.data)

    return response
  },
  async getCurrentUserRolesData (context, userId) {
    let response = await apiCaller.getUserRoles(userId)

    if (apiCaller.isResponseError(response, true)) {
      if (apiCaller.isApiResponseStatusUnauthorized(response)) {
        context.commit('setAskRelog', true)
      }
      return response
    }
    context.commit('setCurrentUserRoles', response.data[0])

    return response
  },
  async getUserInfoFromApi (context, userId) {
    let response = await apiCaller.getUser(userId)

    if (apiCaller.isResponseError(response, true)) {
      if (apiCaller.isApiResponseStatusUnauthorized(response)) {
        context.commit('setAskRelog', true)
      }
      return response
    }
    context.commit('setCurrentUser', response.data)

    return response
  }
}

const mutations = {
  setCurrentUserRoles (state, roles) {
    Vue.set(state, 'currentUserRoles', roles)
  },
  setUsers (state, payload) {
    let users = []

    for (let i in payload) {
      if ((payload[i].mail !== null || payload[i].dvMail !== null) && payload[i].name !== null) {
        users.push(new UserModel(payload[i]))
      }
    }
    Vue.set(state, 'users', users)
  },
  setCurrentUser (state, user) {
    if (user) {
      Vue.set(state, 'currentUser', user.name)
      Vue.set(state, 'currentUserMail', user.mail)
      Vue.set(state, 'currentUserDvMail', user.dv_mail)
      Vue.set(state, 'userStatus', { isDebugger: user.isDebugger, userId: user.user_id, isAm: user.isAm, isSettupper: user.isSettupper, isAdminAndFinance: user.isAdminAndFinance, isSale: user.isSale })
    }
  },
  setApiUsersIsLoaded (state) {
    Vue.set(state, 'apiStatusUsers', API_LOADED)
  },
  setApiUsersIsLoading (state) {
    Vue.set(state, 'apiStatusUsers', API_LOADING)
  },
  setRoleByType (state, payload) {
    Vue.set(state, 'roleByType', payload)
  }
}

const getters = {
  getCurrentUserRoles: state => {
    return state.currentUserRoles
  },
  getCurrentUser: state => {
    return state.currentUser
  },
  getCurrentUserMail: state => {
    return state.currentUserMail
  },
  getCurrentUserDvMail: state => {
    return state.currentUserDvMail
  },
  getCurrentUserAvailableMail: state => {
    return state.currentUserDvMail ? state.currentUserDvMail : state.currentUserMail
  },
  getUsers: state => {
    return state.users
  },
  getUsersName: state => {
    return state.users.map((item) => {
      return item.name
    }).sort()
  },
  getDebuggers: state => {
    return state.users.filter((item) => {
      return item.isDebugger
    })
  },
  getDebuggersName: state => {
    return state.users.filter((item) => {
      return item.isDebugger
    }).map((item) => {
      return item.name
    }).sort()
  },
  getOnlyAm: state => {
    return state.users.filter((item) => {
      return item.isAm
    })
  },
  getOnlyAmMail: state => {
    return state.users.filter((item) => {
      return item.isAm
    }).map((item) => {
      return item.dvMail ? item.dvMail : item.mail
    })
  },
  getOnlyAmName: state => {
    return state.users.filter((item) => {
      return item.isAm
    }).map((item) => {
      return item.name
    })
  },
  isUserDebugger: state => {
    return state.userStatus.isDebugger
  },
  isUserSettupper: state => {
    return state.userStatus.isSettupper
  },
  isUserAdminAndFinance: state => {
    return state.userStatus.isAdminAndFinance
  },
  isUserAm: state => {
    return state.userStatus.isAm
  },
  getCurrentUserId: state => {
    return state.userStatus.userId
  },
  getUserStatus: state => {
    return state.userStatus
  },
  isUserApiLoading: state => {
    return state.apiStatusUsers === API_LOADING
  },
  isUserStatusSet: state => {
    return !state.userStatus || !state.userStatus.userId
  },
  getRoleByType: state => {
    return state.roleByType
  }
}

export const users = {
  actions,
  mutations,
  state,
  getters
}
