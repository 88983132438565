import { Vue } from '../index'

/**
 * @param state
 * @param payload {boolean}
 */
const askRelogMutation = (state, payload) => {
  Vue.set(state, 'askRelog', payload)
}

const state = {
  /**
   * when 401 unauthorized status has been emit by the API, during getAlertsData or getUsers, this value can be set to true
   * who will display a snack bar message to ask to the user for logout, and reconnect
   */
  askRelog: false,
  askErrorMessage: false,
  errorMessage: '',
  askSuccessMessage: false,
  successMessage: ''
}

const actions = {
  debug (_, elem) {
    console.log('DEBUG:', elem)
  }
}

const mutations = {
  setAskRelog (state, payload) {
    askRelogMutation(state, payload)
  },
  setErrorMessage (state, errorMessage) {
    Vue.set(state, 'askErrorMessage', true)
    Vue.set(state, 'errorMessage', errorMessage)
  },
  setSuccessMessage (state, successMessage) {
    Vue.set(state, 'askSuccessMessage', true)
    Vue.set(state, 'successMessage', successMessage)
  },
  setErrorMessageWithResponse (state, errorResponse) {
    let message = errorResponse.response && errorResponse.response.data && errorResponse.response.data.errors
      ? errorResponse.response.data.errors
      : errorResponse

    if (errorResponse?.response?.status === 401) {
      askRelogMutation(state, true)
      return
    }

    const isErrorNoRight = (error) => {
      const sentenceNoRight = 'You don\'t have the right to access this endpoint'
      return typeof error === 'string' && error.includes(sentenceNoRight)
    }

    if (isErrorNoRight(message)) {
      message += '. A member from the tech team may grant you this additionnal right if you share your issue in a support chan.'
    }

    Vue.set(state, 'askErrorMessage', true)
    Vue.set(state, 'errorMessage', message)
  },
  setSuccessMessageWithResponse (state, successResponse) {
    let message = successResponse && successResponse.data && successResponse.data.message
      ? successResponse.data.message
      : successResponse

    Vue.set(state, 'askSuccessMessage', true)
    Vue.set(state, 'successMessage', message)
  },
  errorMessageHasBeenSend (state) {
    Vue.set(state, 'askErrorMessage', false)
    Vue.set(state, 'errorMessage', '')
  },
  successMessageHasBeenSend (state) {
    Vue.set(state, 'askSuccessMessage', false)
    Vue.set(state, 'successMessage', '')
  }
}

const getters = {
  getAskRelog: state => {
    return state.askRelog
  },
  getAskErrorMessage: state => {
    return state.askErrorMessage
  },
  getErrorMessage: state => {
    return state.errorMessage
  },
  getAskSuccessMessage: state => {
    return state.successMessage
  },
  getSuccessMessage: state => {
    return state.successMessage
  }
}

export const common = {
  actions,
  mutations,
  state,
  getters
}
