export const toMergeCommonMarkupModule: Array<{ prop: Array<string> }> = [
  { prop: ['markup_management_is_inherited_from_seat'] },
  { prop: ['markup_module', 'state'] },
  { prop: ['markup_module', 'granularity'] },
  { prop: ['markup_module', 'action'] },
  { prop: ['markup_module', 'modification_mode'] },
  { prop: ['markup_module', 'max_avg_mrkp_during_period'] },
  { prop: ['markup_module', 'min_nominal_mrkp'] },
  { prop: ['markup_module', 'use_first_push_mrkp_as_min_nominal'] },
  { prop: ['markup_module', 'max_nominal_mrkp'] },
  { prop: ['markup_module', 'kpi_eval_period_in_days'] },
  { prop: ['markup_module', 'use_billing_period_as_kpi_eval_period'] },
  { prop: ['markup_module', 'avg_mrkp_eval_period_in_days'] },
  { prop: ['markup_module', 'use_billing_period_as_avg_mrkp_eval_period'] },
  { prop: ['markup_module', 'control_mrkp_at_pof_lvl'] },
  { prop: ['markup_module', 'avg_daily_mrkp_at_pof_lvl'] },
  { prop: ['markup_module', 'markup_fee_designation_keywords'] },
  { prop: ['markup_module', 'no_keywords'] }
]

export const toMergeCommonCustomProdMgmt: Array<{ prop: Array<string> }> = [
  { prop: ['custom_prod_management_is_inherited_from_seat'] },
  { prop: ['custom_prod_management_module', 'underdelivery_custom'] },
  { prop: ['custom_prod_management_module', 'inverse_otto_waterfall'] },
  { prop: ['custom_prod_management_module', 'overdelivery_custom'] },
  { prop: ['custom_prod_management_module', 'respect_minimum_li_budget'] }
]
