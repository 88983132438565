import { processHostEnv } from '../../envHelper'

const authOptions = {
  /**
   * callback path of the api
   */
  apiCallBackUrl: processHostEnv(process.env.VUE_APP_API_HOST) + '/callback',
  /**
   * callback path of the front end
   */
  callbackPath: processHostEnv(process.env.VUE_APP_FRONT_HOST) + '/callback',
  /**
   * auth path of the api
   */
  baseUrlAuth: processHostEnv(process.env.VUE_APP_API_HOST) + '/auth',
  /**
   * the page where the user is redirected at the end of the process
   */
  mainPagePath: processHostEnv(process.env.VUE_APP_FRONT_HOST)
}

export default authOptions
