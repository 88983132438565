/**
 * @param search {string}
 * @param am {array}
 * @param hide {boolean}
 * @returns {string}
 */
export function setQueryStringAlertings (search, am, hide) {
  let searchList = [
    {
      key: 'q_search',
      value: search
    },
    {
      key: 'hide_done_and_false_positive',
      value: hide ? 1 : 0
    },
    {
      key: 'am',
      value: am ? am.join(';') : null
    }
  ]

  return _createQueryString(searchList)
}

/**
 * @param searchList Array<{key: string, value: string | null}>
 * @return {string}
 * @private
 */
function _createQueryString (searchList) {
  return '?' + searchList.filter(item => item.value !== null && item.value !== undefined && item.value !== '')
    .map(item => `${item.key}=${item.value}`).join('&')
}

/**
 *
 * @param search {string}
 * @returns {string}
 */
export function setQueryStringKeystone (search) {
  return `?q_search=${search}`
}
