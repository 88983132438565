import Vue from 'vue'
import Vuex from 'vuex'

import ScibidsApiCaller from '../utils/Api/scibidsApiCaller.js'
import apiConfig from '../utils/Api/apiConfig.js'

Vue.use(Vuex)

export {
  Vue
}

// if the script is executed from node, do not use localStorage
const userJwt = typeof window !== 'undefined' ? localStorage.getItem('user_jwt') : null
export const apiCaller = new ScibidsApiCaller(apiConfig, userJwt)

// DOC : https://vuex.vuejs.org/fr/api/
// Contain general values who can be access in every components of vue.js
// Some constant are defined here and put in Vue.prototype in main.js for avoid error when setting status

export const NORMAL = 'NORMAL'
export const HAVE_JUST_DONE_BULK = 'HAVE_JUST_DONE_BULK'
export const FORM_IS_OPEN = 'FORM_IS_OPEN'
export const EDIT = 'EDIT'
export const NEW = 'NEW'

export const API_LOADING = 'API_LOADING'
export const API_LOADED = 'API_LOADED'

export const DRAFT = 'DRAFT'
export const TO_VALIDATE = 'TO_VALIDATE'
export const VALIDATED = 'VALIDATED'
export const REMOVED = 'REMOVED'

// modules are registered dynamically in the corresponding view
export default new Vuex.Store({})
