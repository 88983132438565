import { UserRole, UserStatusState } from '../../types/user_types'
import Plausible from 'plausible-tracker'
import { EventOptions } from 'plausible-tracker/build/main/lib/request'
import { PlausibleOptions } from 'plausible-tracker/src/lib/tracker'
import { SurcouchePlausibleEvents } from './events_type'
import { processHostEnv } from '../../envHelper'

/**
 * class helper for working with plausible (analytics : https://plausible.io/).
 * Implement all member of the ReturnType<typeof Plausible> and serve as proxy for the plausible module.
 * Use and init the plausible lib depending on the current env config (https://www.npmjs.com/package/plausible-tracker)
 */
export default class PlausibleHelper implements ReturnType<typeof Plausible> {
  plausible: ReturnType<typeof Plausible>

  constructor () {
    this.plausible = this.initPlausible()
    this.plausible.enableAutoPageviews()
  }

  initPlausible (): ReturnType<typeof Plausible> {
    return Plausible({
      domain: (function () {
        // remove https from frontHost (domain don't need it)
        let frontHost = processHostEnv(process.env.VUE_APP_FRONT_HOST)

        if (frontHost.startsWith('https://')) {
          frontHost = frontHost.replace('https://', '')
        } else if (frontHost.startsWith('http://127.0.0.1:9000')) {
          // on localhost, we return staging url
          frontHost = 'internal-interface-dot-webinterfacetest-1.appspot.com'
        }
        return frontHost
      }()),
      trackLocalhost: (function () {
        // allow localhost tracking for test when on http://127.0.0.1 (dev)
        return processHostEnv(process.env.VUE_APP_FRONT_HOST).startsWith('http://127.0.0.1')
      }())
    })
  }

  registerPlausibleRole (userStatus: UserStatusState) {
    if (userStatus.isAm) {
      this.trackRole('am')
    }

    if (userStatus.isSale) {
      this.trackRole('sale')
    }

    if (userStatus.isDebugger) {
      this.trackRole('debugger')
    }

    if (userStatus.isSettupper) {
      this.trackRole('settupper')
    }

    if (userStatus.isAdminAndFinance) {
      this.trackRole('admin_and_finance')
    }
  }

  doActionAndTrackEvent (actionCallback: () => void, eventName: string, options?: EventOptions) {
    actionCallback()
    this.plausible.trackEvent(eventName, options)
  }

  trackPageview () {
    return this.plausible.trackPageview()
  }

  trackRole (role: UserRole) {
    this.trackSurcoucheEvent('role', { props: { role: role } })
  }

  trackIoFormChangeTab (tab: string) {
    this.trackSurcoucheEvent('on_tab', { props: { tab: tab } })
  }

  /**
   * proxy for trackEvent but with a typed eventName with the event registered in the Plausible console.
   */
  trackSurcoucheEvent (
    eventName: SurcouchePlausibleEvents,
    options?: EventOptions,
    eventData?: PlausibleOptions
  ) {
    return this.trackEvent(eventName, options, eventData)
  }

  trackEvent (
    eventName: string,
    options?: EventOptions,
    eventData?: PlausibleOptions
  ) {
    return this.plausible.trackEvent(eventName, options, eventData)
  }

  enableAutoOutboundTracking (targetNode: (Node & ParentNode) | undefined, observerInit: MutationObserverInit | undefined): () => void {
    return this.plausible.enableAutoOutboundTracking(targetNode, observerInit)
  }

  enableAutoPageviews (): () => void {
    return this.plausible.enableAutoPageviews()
  }
}
