export default class SurcoucheConfig {
  /**
   * @param getConfig {function} a function who return a config
   */
  constructor (getConfig) {
    this.__config = null
    this.isLoading = false
    this.getters = {}
    this.forceFrontEndMaintenance = false

    if (typeof getConfig !== 'function') {
      throw TypeError(`getConfig arg must be a function. ${typeof getConfig} received.`)
    }
    this.getConfig = getConfig
  }

  refreshConfig (force = false) {
    if (this.isLoading && !force) {
      return true
    }
    console.warn('[SurcoucheConfig.js] Surcouche config is refresh...')
    this.isLoading = true
    return this.getConfig()
      .then((data) => {
        if (!data) {
          console.warn('[SurcoucheConfig.js] Error when loading the config')
          return false
        }
        this.__config = data
        for (let key in this.__config) {
          this.getters[key] = this.__config[key]
        }

        if (this.forceFrontEndMaintenance) {
          this.getters.ON_MAINTENANCE = true
        }

        this.isLoading = false
        return true
      })
      .catch((error) => {
        this.isLoading = false
        console.warn(error)
        return false
      })
  }

  get (key) {
    if (this.__config === undefined || this.__config === null) {
      let error = 'No config loaded !'
      // throw new ConfigNotLoaded(error)
      return null
    }

    if (typeof this.__config !== 'object' || !(key in this.__config)) {
      console.warn(`can't find ${key} in config.`)
      return null
    }

    return this.__config[key]
  }

  get isConfigLoaded () {
    return this.__config !== null && this.__config !== undefined && typeof this.__config === 'object'
  }

  get isConfigLoading () {
    return this.isLoading
  }

  get isConfigLoadedOrLoading () {
    return this.isConfigLoaded || this.isConfigLoading
  }
}

function ConfigNotLoaded (message) {
  this.message = message
  // Use V8's native method if available, otherwise fallback
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this, ConfigNotLoaded)
  } else {
    this.stack = (new Error()).stack
  }
}
