import _ from 'lodash'
import { UserStatusState } from '../types/user_types'
import { KpiText, KpiValue } from '../types/brief_enum'

export function userHexColor (name: string): string {
  let colors = ['#b9cc98', '#03396c', '#011f4b', '#008080', '#f36b2c', '#f4f6f8', '#f36b2c', '#f8f8f8', '#9c9c9c', '#444444', '#a40b0b', '#d5bdbd', '#f01d6a', '#d5bdbd', '#60a1f0', '#fceca4', '#5fddc1', '#00cc2c', '#6af6d7', '#e39cf7', '#65737e', '#a7adba', '#ffe39f', '#9b59b6', '#2ecc71', '#f1c40f', '#3498db', '#1abc9c', '#493267', '#9e379f', '#011f4b', '#6497b1', '#005b96', '#03396c', '#536878', '#0095b6', '#7df9ff', '#be9b7b', '#fff4e6', '#854442', '#222f5b']
  let hashed = str2hash(name)
  let index = hashed % colors.length
  let color = colors[index]
  return color
}

export function str2hash (s: string): number {
  let nHash = 0
  if (!s.length) return nHash
  for (let i = 0, imax = s.length, n; i < imax; ++i) {
    n = s.charCodeAt(i)
    nHash = ((nHash << 5) - nHash) + n
    nHash = nHash & nHash // Convert to 32-bit integer
  }
  return Math.abs(nHash)
}

// https://wikimedia.org/api/rest_v1/media/math/render/svg/7f26a3fa4accb7cd0835a461b624f67e859c59bb
export function javaStr2Hash (s: string): number {
  let sHash = 0

  for (let i = 0, imax = s.length, n; i < imax; i++) {
    n = s.charCodeAt(i)
    let power = imax - 1 - i
    sHash += n * Math.pow(31, power)
    sHash = sHash & sHash
  }

  return Math.abs(sHash)
}

export function createDateAsUTC (date: Date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), date.getHours(),
    date.getMinutes(), date.getSeconds()))
}

export function roundNum (n: number, precision: number = 2): number {
  if (n === 0 || isNaN(n)) {
    return 0
  }
  return Math.round(n * Math.pow(10, precision)) / Math.pow(10, precision)
}

/**
 * is toTest numeric ?
 */
export function isNumeric (toTest: any): boolean {
  return !isNaN(toTest)
}

/**
 * transform a ratio between 0 and 1 to a percentage
 */
export function ratioToPerc (n: number): number {
  return Math.round(n * 100)
}

// DATE FUNCTION
/***
 * Transform an iso date to a displayable string
 * @param isoDate {string} a string date with a form like => 2019-05-20T09:53:35.004302+00:00
 * @param precise {boolean} (default true) : set it to false if you just want year month and day
 * @param toLocal {boolean} (default false) : set it to true if you want to convert the date to local timezone
 * @param addInfoTimezone {boolean} (default false) : set it to true if you want to add the timezone
 * info at the end of the date in the form (UTC+<THE_TIMEZONE_INFO>) ex : (UTC+02)
 * @return {string}
 */
export function transformIsoDate (isoDate: string, precise: boolean = true, toLocal = false, addInfoTimezone = false): string {
  let date = isoDateToDate(isoDate)
  let timezoneOffset = date.getTimezoneOffset()
  let explodedDate = explodeDateString(date, toLocal)

  let dateStr = explodedDate.year + '-' + explodedDate.month + '-' + explodedDate.day

  if (precise) {
    dateStr += ' (' + explodedDate.hours + ':' + explodedDate.minutes + ')'
  }

  if (addInfoTimezone) {
    dateStr += ' (UTC' + (timezoneOffset > 0 ? '-' : '+') + Math.abs(timezoneOffset / 60) + ')'
  }

  return dateStr
}

/**
 * transform isoDate to Date object
 */
export function isoDateToDate (isoDate: string): Date {
  /**
   * special case for microsoft Edge where the Date string put in the constructor of the Date Object MUST BE like 2019-05-20T09:53:35.004302+00:00
   * date string like 2019-05-20 09:53:35.004302+00:00 doesn't work
   */
  if (typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Edge') !== -1) {
    isoDate = isoDate.replace(' ', 'T')
  }

  return new Date(isoDate)
}

export function formatDate (isoDate: string = null, withSeconds: boolean = true): string {
  let date = null

  if (isoDate !== null) {
    date = new Date(isoDate)
  } else {
    date = new Date()
  }

  let explodedDate = explodeDateString(date)

  let baseString = `${explodedDate.year}-${explodedDate.month}-${explodedDate.day} ${explodedDate.hours}:${explodedDate.minutes}`
  let secondsString = `${withSeconds ? ':00' : ''}`
  return `${baseString}${secondsString}`
}

export function formatDateWithSeconds (isoDate: string = null): string {
  let date = null

  if (isoDate !== null) {
    date = new Date(isoDate)
  } else {
    date = new Date()
  }

  let explodedDate = explodeDateString(date)

  return `${explodedDate.year}-${explodedDate.month}-${explodedDate.day} ${explodedDate.hours}:${explodedDate.minutes}:${explodedDate.seconds}`
}

export function formatDateStratMode (isoDate: string = null, infoTz = 'CET'): string {
  return `${infoTz} ${formatDate(isoDate)}`
}

export function dateToLocal (date: Date): Date {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
}

export function explodeDateString (date: Date, toLocal = false): {year: string, month: string, day: string, hours: string, minutes: string, seconds: string} {
  // clone date object
  let cloneDate = new Date(date.getTime())
  if (toLocal) {
    cloneDate = dateToLocal(cloneDate)
  }
  let year = cloneDate.getFullYear().toString()

  let month = (cloneDate.getMonth() + 1).toString()
  month = month.length < 2 ? '0' + month : month

  let day = cloneDate.getDate().toString()
  day = day.length < 2 ? '0' + day : day

  let hours = (cloneDate.getHours()).toString()
  hours = hours.length < 2 ? '0' + hours : hours

  let minutes = (cloneDate.getMinutes()).toString()
  minutes = minutes.length < 2 ? '0' + minutes : minutes

  let seconds = (cloneDate.getSeconds()).toString()
  seconds = seconds.length < 2 ? '0' + seconds : seconds

  return {
    year: year,
    month: month,
    day: day,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

export function dateToStringYMD (date: Date): string {
  let month: string|number = date.getUTCMonth() + 1
  if (month < 10) {
    month = `0${month}`
  }
  let day: string|number = date.getUTCDate()
  if (day < 10) {
    day = `0${day}`
  }
  return `${date.getUTCFullYear()}-${month}-${day}`
}

export function getIntersection (...arrays: any[]) {
  let intersection = []
  const numberOfArrays = arrays.length

  for (let i = 0; i < numberOfArrays; i++) {
    let currentArray = arrays[i]
    for (let n in currentArray) {
      // if value already in intersection, don't need to search for this value again
      if (intersection.indexOf(currentArray[n]) !== -1) {
        continue
      }
      for (let y = 0; y < numberOfArrays; y++) {
        // dont search in the same array
        if (y === i) {
          continue
        }
        // value is not here, and not a intersection value
        if (arrays[y].indexOf(currentArray[n]) === -1) {
          break
        }
        // if we are here and it is the last array, that mean than not break happened, and the value is in all arrays
        if (y === numberOfArrays - 1) {
          intersection.push(currentArray[n])
        }
      }
    }
  }
  return intersection
}

export function getUnion (...arrays: Array<Array<number|string>>): Array<number|string> {
  let union: any[] = []
  for (let i in arrays) {
    for (let j in arrays[i]) {
      if (union.indexOf(arrays[i][j]) === -1) {
        union.push(arrays[i][j])
      }
    }
  }
  return union
}

export function isInclude<T = number|string> (array: T[], ...arrays: Array<T[]>) : boolean {
  for (let i in arrays) {
    for (let j in arrays[i]) {
      let toTest = arrays[i][j]
      if (array.indexOf(toTest) === -1) {
        return false
      }
    }
  }
  return true
}

export function getDoublon<T = number|string> (...arrays: Array<T[]>) {
  let doublon = []
  const numberOfArrays = arrays.length

  for (let i = 0; i < numberOfArrays; i++) {
    let currentArray = arrays[i]
    for (let y = 0; y < numberOfArrays; y++) {
      // dont compare with the same array
      if (i === y) {
        continue
      }
      for (let n in currentArray) {
        // if value already in doublon, don't need to search for this value again
        if (doublon.indexOf(currentArray[n]) !== -1) {
          continue
        }

        if (arrays[y].indexOf(currentArray[n]) !== -1) {
          doublon.push(currentArray[n])
        }
      }
    }
  }
  return doublon
}
/**
 * test equality of array with primitive value only
 * @param a
 * @param b
 * @returns {boolean}
 */
export function isEqualPrimitiveArray (a: Array<number|string>, b: Array<number|string>) {
  return a.sort().toString() === b.sort().toString()
}

/**
 * @function
 * @template Z
 * @param {Z} object
 * @returns {Z} the deep copy of object
 */
export function deepCopy<Z extends object> (object: Z): Z {
  return _.cloneDeep(object)
}

export function capitalize (s: string): string {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * @param {object} object the object where looking
 * @param {string} propsString must be formatted like that : 'field.nestedField.nestedField'
 * for example, if you want to know if object.user.name is setted, you must use the function
 * like that : issetInObject(object, 'user.name')
 * @returns {boolean} if propsString is setted in object
 */
export function issetInObject (object: {[key: string]: any}, propsString: string): boolean {
  if (typeof object !== 'object') {
    throw new TypeError('object must be of type object, ' + typeof object + ' received')
  }

  if (typeof propsString !== 'string') {
    throw new TypeError('propsString must be of type string ' + typeof propsString + ' received')
  }

  let propsArray = propsString.split('.')
  let currentObject = object

  for (let i in propsArray) {
    if (currentObject[propsArray[i]] === undefined || currentObject[propsArray[i]] === null || currentObject[propsArray[i]] === '') {
      return false
    }
    currentObject = currentObject[propsArray[i]]
  }
  /**
   * if we are here, that means the prop is setted in the object
   * true is returned
   */
  return true
}

/**
 * @param {object} object
 * @returns {boolean} : is the object empty ?
 */
export function objectIsEmpty (object: {[key: string]: any}) {
  if (typeof object !== 'object') {
    throw new TypeError('object arg need to be a Object.' + typeof object + ' received.')
  }
  for (let key in object) {
    if (object.hasOwnProperty(key)) { return false }
  }
  return true
}

/**
 * test if 2 Object (Object or Array) are equal
 * thx to ChrisFerdinandi => https://gomakethings.com/check-if-two-arrays-or-objects-are-equal-with-javascript/
 * @param {Object} value
 * @param {Object} other
 * @returns {Boolean} is the Object are equal ?
 */
export function isEqual (value: object, other: object) {
  // Get the value type
  let type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  let valueLen = type === '[object Array]' ? (<Array<any>>value).length : Object.keys(value).length
  let otherLen = type === '[object Array]' ? (<Array<any>>value).length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items
  let compare = function (item1: object, item2: object) {
    // Get the object type
    let itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) {
        return false
      }
    } else { // Otherwise, do a simple comparison
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) {
        return false
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) {
          return false
        }
      } else {
        if (item1 !== item2) {
          return false
        }
      }
    }
  }

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare((value as any[])[i], (other as any[])[i]) === false) return false
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare((value as {[key: string]: any})[key], (other as {[key: string]: any})[key]) === false) return false
      }
    }
  }

  // If nothing failed, return true
  return true
}

/**
 * return the fields who are not equal between 2 objects. Useful for debugging
 * @param value
 * @param other
 */
export function whichFieldAreNotEqual (value: object, other: object) {
  let fieldNotEqual = []
  for (let i in value) {
    /** @ts-ignore **/
    if (value[i] !== undefined && value[i] !== null && typeof value[i] === 'object') {
      // @ts-ignore
      if (!isEqual(value[i], other[i])) {
        // @ts-ignore
        console.log(value[i])
        // @ts-ignore
        console.log(other[i])
        fieldNotEqual.push(i)
      }
    } else {
      // @ts-ignore
      if (value[i] !== other[i]) {
        // @ts-ignore
        console.log('', value[i])
        // @ts-ignore
        console.log(other[i])
        fieldNotEqual.push(i)
      }
    }
  }
  return fieldNotEqual
}

/**
 * slice object
 */
export function sliceObject (obj: {[key: string]: any}, start: number, end: number): {[key: string]: any}[] {
  return Object.keys(obj).slice(start, end).map(key => ({ [key]: obj[key] }))
}

/**
 *  return a array with all the duplicate of the param 'array'
 */
export function detectDuplicateInArray (array: Array<any>): Array<any> {
  return array.reduce((acc, current, i, array) => {
    // array without the current element
    let a = Array.from(array)
    a.splice(i)
    // test if the element is again in this new array
    if (a.indexOf(current) !== -1) {
      // the element is again in the new array
      acc.push(current)
    }

    return acc
  }, [])
}

/**
 * return a Array of number stating at 'startAt' and with the size 'size'
 */
export function rangeNum (size: number, startAt: number = 0): number[] {
  return [...Array(size).keys()].map(i => i + startAt)
}

/**
 * transform a camel case to a string
 * ex : oneCamelCase => one camel case
 */
export function camelCaseToString (str: string): string {
  return str.replace(/([a-zA-Z])([A-Z])/g, '$1 $2').toLowerCase()
}

/**
 * transform a camel case to a capitalize string
 * ex : oneCamelCase => One camel case
 */
export function camelCaseToCapitalizedString (str: string): string {
  return capitalize(camelCaseToString(str))
}

/**
 * transform a camel case to a capitalized words string
 * ex : oneCamelCase => One Camel Case
 */
export function camelCaseToCapitalizedWordsString (str: string): string {
  const words = camelCaseToString(str).split(' ')
  let newStr: string = ''
  for (let i = 0; i < words.length; i++) {
    newStr += capitalize(words[i]) + `${i + 1 < words.length ? ' ' : ''}`
  }
  return newStr
}

export function camelToSnake (string: string) {
  return string.replace(/[\w]([A-Z])/g, function (m) {
    return m[0] + '_' + m[1]
  }).toLowerCase()
}

/**
 * @param object {object}
 * @param str {string} must be in dot notation ('field.nestedField.nestednestedField')
 * @returns {undefined|*}
 */
export function safeGet (object: {[key: string]: any}, str: string) {
  let exploded: string[] = []
  try {
    exploded = str.split('.')
  } catch (TypeError) {
    let message = `ERROR in safe get. \n Object : ${JSON.stringify(object)} \n str  : ${str}`
    console.warn(message)
    console.trace()
  }
  let currentObject = object

  for (let i in exploded) {
    let field = exploded[i]

    if (currentObject[field] === undefined || currentObject[field] === null) {
      return undefined
    }

    currentObject = currentObject[field]
  }

  return currentObject
}

export function safeSet (object: {[key: string]: any}, str: string, value: any) {
  let exploded = str.split('.')
  let currentObject = object
  let field
  for (let i in exploded) {
    if (!exploded.hasOwnProperty(i)) {
      continue
    }
    field = exploded[i]

    if (currentObject[field] === undefined || currentObject[field] === null) {
      currentObject[field] = {}
    }
    if (Number(i) !== exploded.length - 1) {
      currentObject = currentObject[field]
    }
  }

  currentObject[field] = value
}

/**
 * return the diff between 2 isoDate in milliseconds. If date2 is null, date1 is compared to today
 */
export function getDiffDate (date1: string, date2: string = null): number {
  let date2Date
  if (date2 === null) {
    date2Date = new Date() // no date = today
  } else {
    date2Date = new Date(date2)
  }
  let date2Timestamp = date2Date.getTime()

  let date1Date = new Date(date1)
  let date1Timestamp = date1Date.getTime()

  return Math.abs(date2Timestamp - date1Timestamp)
}

/**
 * substract to the Date date the number of day dayToSubstract
 */
export function substractDate (date: Date, dayToSubstract: number): Date {
  return new Date(date.setDate(date.getDate() - dayToSubstract))
}

/**
 * node sleep function
 */
export function sleep (ms: number = 1000): Promise<unknown> {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

/**
 * copy instance of a class. Thx to => https://www.nickang.com/how-to-clone-class-instance-javascript/
 */
export function copyInstance<T extends object> (original: T): T {
  return Object.assign(
    Object.create(
      Object.getPrototypeOf(original)
    ),
    original
  )
}

export function sliceIfTooLength (toSlice: string, maxLength: number, append: string = '...'): string {
  if (typeof toSlice !== 'string') {
    return ''
  }
  return toSlice.length > maxLength ? toSlice.slice(0, maxLength) + append : toSlice
}

export function countDecimal (value: number|string): number {
  if (Math.floor(Number(value)) === value) return 0
  let separator = '.'
  // maybe ',' is used as decimal separator
  if (value.toString().indexOf('.') === -1) {
    if (value.toString().indexOf(',') === -1) {
      return 0
    }
    separator = ','
  }
  return value.toString().split(separator)[1].length || 0
}

export function getErrorInResponse (response: any) {
  return response && response.response && response.response.data && response.response.data.errors
    ? response.response.data.errors
    : response
}

/**
 * Should be used on each string coming from the backend and who will be used in a "v-html" directive.
 * It will escape all html entities, to avoid XSS attacks.
 */
export function htmlEntities<T extends (string | number)> (str: T): T {
  return typeof str === 'string' ? _.escape(str) as T : str
}

/**
 * Same as htmlEntities, but for a list of strings or number
 * @param list
 */
export function htmlEntitiesList (list: Array<string | number>): Array<string | number> {
  return list.map((str) => {
    return htmlEntities(str)
  })
}

export function hasUserCorrectStatus (
  userStatus: UserStatusState,
  roles: Array<keyof UserStatusState> | keyof UserStatusState = null,
  noDebuggerOrSettupper: boolean = false
) {
  // Top level roles, everything is allowed
  if (!noDebuggerOrSettupper && (userStatus.isSettupper || userStatus.isDebugger)) {
    return true
  }

  // Transform to array of roles
  if (!(roles instanceof Array)) {
    roles = [roles]
  }

  // Check depending on role(s) specified
  if ((roles instanceof Array) && roles.length > 0) {
    for (let role of roles) {
      if (userStatus[role]) { return true }
    }
  }
  return false
}

export function sortDate (a: string, b: string) {
  let dateA = new Date(a).getTime()
  let dateB = new Date(b).getTime()
  return dateB - dateA
}

export function dateToIsoCalendar (date: Date) {
  return date.toISOString().substr(0, 10)
}

export function generateDigitMask () {
  return rangeNum(40).map((n) => {
    return '#'.repeat(n)
  })
}

export function isSetAndNotEmpty (value: any) {
  return !!value
}

export function isNotNullOrUndefined (value: any) {
  return value != null
}

export function roundPercentage (ratio: number) {
  return Math.round(ratio * 100 * 1000) / 1000
}

export function checkMultipleErrorMessages (conditionsArray: Array<any>): string {
  for (let condition of conditionsArray) {
    if (condition == null) { continue }
    // If the result is in an array, else just check the condition
    if (condition instanceof Array && !(['', null, undefined].includes(condition[0]))) {
      return condition[0]
    } else if (!(['', null, undefined].includes(condition[0]))) {
      return condition
    }
  }
  return ''
}

export function dspPrettyPrint (dsp: string, externalNames: boolean = true) {
  switch (dsp) {
    case 'mediamath':
    case 'beeswax':
    case 'kayzen':
      return dsp[0].toUpperCase() + dsp.substring(1).toLowerCase()
    case 'appnexus':
      return externalNames ? 'Xandr' : 'Appnexus'
    case 'dbm':
      return externalNames ? 'DV360' : 'DBM'
    case 'youtube':
      return 'YouTube'
    case 'thetradedesk':
      return 'TheTradeDesk'
    default:
      return dsp
  }
}

export function kpiValueToKpiText (kpiValue: KpiValue): KpiText {
  return (KpiText as any)[kpiValue as string]
}

export function endsWithAny (suffixes: Array<string>, str: string): boolean {
  return suffixes.some((suffix: string) => {
    return str.endsWith(suffix)
  })
}

export function getCollaboratorNameFromMail (mail: string): string {
  if (mail == null) {
    return ''
  }
  const split = mail.split('@')
  return split.length > 0 ? split[0] : mail
}

/**
 * @param object {object}
 * @param test {function} the function to evaluate if the value is removed from the object
 */
export function removeFromObject (object: Record<string, any>, test: Function) {
  for (let i in object) {
    if (test(object[i])) {
      delete object[i]
    } else if (typeof object[i] === 'object') {
      removeFromObject(object[i], test)
      if (!Array.isArray(object[i]) && objectIsEmpty(object[i])) {
        // delete object[i]
        object[i] = {}
      }
    }
  }
}

/**
 * recursive function for removing empty , null or undefined values from a object
 * if the object contain reference to object who contain only empty, null
 * and undefined values, the object is deleted
 * @param {object} object
 */
export function removeEmptyFieldFromObject (object: Record<string, any>) {
  let testEmpty = function (toTest: any) {
    return toTest === undefined || toTest === '' || toTest === null
  }

  removeFromObject(object, testEmpty)
}

/**
 * recursive function for removing empty , null or undefined values from a object
 * if the object contain reference to object who contain only empty, null
 * and undefined values, the object is deleted
 * @param {object} object
 */
export function removeNullAndUndefinedValueFromObject (object: Record<string, any>) {
  let testNullOrUndefined = function (toTest: any) {
    return toTest === undefined || toTest === null
  }

  removeFromObject(object, testNullOrUndefined)
}

/**
 * @param id {string | number} the id of the element to remove
 */
export function removeElementFromDom (id: string | number) {
  let elementToRemove = document.getElementById(String(id))
  if (elementToRemove) {
    elementToRemove.remove()
  }
}

type CsvObjectType = { [headerName: string]: string | number | object } // Still better than "any"
export function createCsvStringFromObjectWithHeaders (obj: Array<CsvObjectType>, headers: Array<string>): string {
  const headerLine = `${headers.join(',')}\n`
  let contentLines = ''
  obj.forEach((objLine) => {
    headers.forEach((header: string, index: number) => {
      contentLines += `${objLine[header] != null ? objLine[header] : ''}${index + 1 < headers.length ? ',' : ''}`
    })
    contentLines += '\n'
  })
  return `${headerLine}${contentLines}`
}

export function downloadFileHandler (filename: string, content: string, type: string) {
  const blob = new Blob([content], { type: type })
  const link = document.createElement('a')
  link.download = filename
  link.href = window.URL.createObjectURL(blob)
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    document.body.removeChild(link)
    window.URL.revokeObjectURL(link.href)
  }, 100)
}

export function joinStringArray (arr: Array<string>): string {
  if (arr == null || arr.length === 0) {
    return ''
  }
  return arr.join(',')
}
