import {
  $DBM,
  $YOUTUBE,
  $BEESWAX,
  $MEDIAMATH,
  $THETRADEDESK,
  $APPNEXUS,
  $FACEBOOK,
  $OPTIMIZE_CAMPAIGNS,
  $OPTIMIZE_STRATEGIES,
  $OPTIMIZE_LINEITEMS,
  $OPTIMIZE_ADSETS,
  $OPTIMIZE_ADGROUPS,
  $KAYZEN
} from '../../config/dspConfig'
import { getUnion, isInclude, getDoublon, capitalize } from '../commonUtils'
import {
  getRightOptimizeField,
  isOptimizeValueAll,
  isOptimizeValueAllBut,
  isOptimizeValueOnly
} from '../instructionsUtils'

export const ONLY = 'only'
export const ALLBUT = 'all but'
export const ALL = 'all'
const ERROR_MESSAGE_DSP_MUST_BE_SETTED = 'You must set a dsp (with BusinessRulesChecker::setDsp or in the constructor)'

const MESSAGE_ONLY_ONE_INTRUCTION_WHEN_ALL = `Only one instruction allowed when a line is in '${ALL}'`
const MESSAGE_ONLY_ONE_INSTRUCTION_IN_ALLBUT = `Only one instruction in '${ALLBUT}' allowed`
const MESSAGE_DOUBLON_ONLY = `Collision between instructions with `
const MESSAGE_ONLY_INCLUDED_ALLBUT = `Collision between an instruction with "${ALLBUT}" condition and instruction(s) with `

// appx optimize campaigns field (ca)
export const OPTIMIZE_CAMPAIGNS = $OPTIMIZE_CAMPAIGNS

export const OPTIMIZE_LINEITEMS = $OPTIMIZE_LINEITEMS
export const OPTIMIZE_ADGROUPS = $OPTIMIZE_ADGROUPS
export const OPTIMIZE_STRATEGIES = $OPTIMIZE_STRATEGIES
export const OPTIMIZE_ADSETS = $OPTIMIZE_ADSETS

/**
 * Check if the 'optimize' rules are respected
 * cf => https://docs.google.com/document/d/1PMbeWBrQ2pAX3ZIJN2flIpu5zO54olWSuaeKKrqTXto/edit
 *
 * Usage :
 *
 *   this.optimizeRulesChecker.setInstructionsList(instructionContainer).setDsp(dsp)
 *   return this.optimizeRulesChecker.isValid()
 *
 *
 * _________________________________________
 *
 * Test are available in the test folder
 *
 * _________________________________________
 *
 */
class OptimizeRulesChecker {
  constructor () {
    /**
     * @type {[]}
     */
    this.instructionsList = []
  }

  /**
   * @param toValid {Instruction[]}
   * @param dsp {InstructionDsp|null}
   * @returns {[]} return a list of error messages if one or more rules are not respected
   */
  isValid (toValid, dsp) {
    this.setDsp(dsp)

    if (this.dsp === null) {
      throw Error(ERROR_MESSAGE_DSP_MUST_BE_SETTED)
    }

    return this._isValid(toValid)
  }

  /**
   * for non appnexus dsp
   * @param toValid {Instruction[]}
   * @returns {[]} return a list of error messages if one or more rules are not respected
   */
  _isValid (toValid) {
    this.setToValid(toValid)
    let errorMessage = this._isValidCommon()

    if (!this.onlyDoublonIsEmpty()) {
      errorMessage.push(this.getErrorMessageOnlyDoublon())
    }

    return errorMessage
  }

  /**
   * contain the validation common to all the dsp
   * @param toValid {array}
   * @param idValidated {Number}
   * @returns {[]} return a list of error messages if one or more rules are not respected
   */
  _isValidCommon (toValid = null, idValidated = null) {
    if (toValid !== null) {
      this.setToValid(toValid)
    }

    let errorMessage = []

    if (this.instructionsList === undefined || this.instructionsList === null || this.instructionsList.length === 0) {
      const message = 'No instructions list have been provided. Please provide a list of instructions to check'
      console.warn(`[OptimizeRulesChecker.js] ${message}`)
      return errorMessage
    }

    if (!this.onlyOneInstructionAllowedWhenOneLineInAll()) {
      errorMessage.push(this.getErrorMessageOnlyOneAllAllowed(idValidated))
    }

    if (!this.onlyOneInstructionInAllButAllowed()) {
      errorMessage.push(this.addIdToErrorMessage(MESSAGE_ONLY_ONE_INSTRUCTION_IN_ALLBUT, idValidated))
    }

    if (!this.onlysMustBeIncludedInAllbut()) {
      errorMessage.push(this.getErrorMessageAllButIncludeOnly(idValidated))
    }

    return errorMessage
  }

  /**
   * (special appnexus)
   * group the values op optimize_campaigns field who have the same only's id together (see test)
   */
  groupOnly () {
    const onlysInstructions = this.getTypeOnlyInstructions()
    let grouped = {}

    for (let i in onlysInstructions) {
      let optimizeValue = onlysInstructions[i][OPTIMIZE_LINEITEMS]
      let idList = this.getTypeOnlyIdList(optimizeValue)

      for (let y in idList) {
        let currentId = idList[y]
        if (!grouped.hasOwnProperty(currentId)) {
          grouped[currentId] = []
        }

        grouped[currentId].push(onlysInstructions[i][OPTIMIZE_CAMPAIGNS])
      }
    }

    return grouped
  }

  /**
   * (special case Appnexus)
   * optimize_lineitems field who are not singlon (more than 1 ids) can't have ids in doublon
   */
  onlyNotSinglonDoublonIsEmpty () {
    const typeOnlyInstructions = this.getTypeOnlyInstructions()
    let onlyIdsSinglon = []
    let onlyIdsNotSinglonArray = []

    for (let i in typeOnlyInstructions) {
      const optimizeValue = typeOnlyInstructions[i][this.getRightOptimizeField()]
      if (this.isSinglon(optimizeValue)) {
        onlyIdsSinglon.push(this.getTypeOnlyIdList(optimizeValue)[0])
      } else {
        onlyIdsNotSinglonArray.push(this.getTypeOnlyIdList(optimizeValue))
      }
    }

    return getDoublon(onlyIdsSinglon, ...onlyIdsNotSinglonArray).length === 0
  }

  /**
   * 'only' values must not be in 2 only
   * @returns {boolean} is a value of a 'only' present in another 'only' ?
   */
  onlyDoublonIsEmpty () {
    const listOnly = this.getOnlysValue()
    const doublon = getDoublon(...listOnly)
    return doublon.length <= 0
  }

  /**
   * @returns {boolean} is the instructionsList contain more than one all but ?
   */
  onlyOneInstructionInAllButAllowed () {
    return this.getTypeAllButInstructions().length <= 1
  }

  /**
   * @returns {boolean} is instructionsList contain more than 1 instruction if this one if in 'all'
   */
  onlyOneInstructionAllowedWhenOneLineInAll () {
    return this.getTypeAllInstructions().length === 0 || this.instructionsList.length <= 1
  }

  /**
   * @returns {boolean} is all values of the only included in the 'all but' ?
   */
  onlysMustBeIncludedInAllbut () {
    const field = this.getRightOptimizeField()
    const allButIntructions = this.getTypeAllButInstructions()

    if (allButIntructions.length <= 0) {
      return true
    }

    const optimizeValue = allButIntructions[0][field]
    const allButValues = this.getTypeAllButIdList(optimizeValue)

    const listOnly = this.getOnlysValue()
    const listOnlyUnion = getUnion(...listOnly)

    return isInclude(allButValues, listOnlyUnion)
  }

  /**
   * @return {[]}
   */
  getAllButIdNotInLi () {
    const field = this.getRightOptimizeField()
    const allButInstructions = this.getTypeAllButInstructions()
    let idNotInLi = []

    if (allButInstructions.length <= 0) {
      return idNotInLi
    }

    const optimizeValue = allButInstructions[0][field]
    const allButValues = this.getTypeAllButIdList(optimizeValue)

    const listOnly = this.getOnlysValue()
    const listOnlyUnion = getUnion(...listOnly)

    allButValues.forEach((value, index) => {
      if (listOnlyUnion.indexOf(value) === -1) {
        idNotInLi.push(value)
      }
    })

    return idNotInLi
  }

  warningAllLineInOnlyButNoAllbut () {
    if ([$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $YOUTUBE, $FACEBOOK].indexOf(this.dsp) === -1) {
      return false
    }
    let onlyInstructions = this.getTypeOnlyInstructions()
    let allbutInstructions = this.getTypeAllButInstructions()
    if (this.instructionsList.length === onlyInstructions.length && allbutInstructions.length <= 0) {
      return 'Your set-up may create a partial optimization situation. We strongly encourage you to create an "all but" ' +
        'instruction that will act as a default setup for eventual newly added line item'
    }
  }

  /**
   * @param instructionList {Instruction[]|null}
   */
  warningOnlyMustBeEqualToAllbut (instructionList = null) {
    if ([$MEDIAMATH, $THETRADEDESK, $DBM, $APPNEXUS, $YOUTUBE, $FACEBOOK].indexOf(this.dsp) === -1) {
      return false
    }

    if (instructionList !== null) {
      this.instructionsList = instructionList
    }
    const allButIdNotInLi = this.getAllButIdNotInLi()
    if (allButIdNotInLi.length > 0) {
      return `${capitalize(this.getRightLineItemField())} ID <strong>${allButIdNotInLi.join(', ')}</strong> not taken in account by Prod.`
    }
    return false
  }

  /**
   * @param instructionsList {[]|null}
   * @param dsp {InstructionDsp|null}
   */
  checkWarnings (instructionsList, dsp = null) {
    this.instructionsList = instructionsList
    if (dsp !== null) {
      this.dsp = dsp
    }
    let errorsMessage = []
    let warningNames = []
    let allButIdWithErrors = []

    let result = this.warningOnlyMustBeEqualToAllbut()
    if (result) {
      errorsMessage.push(result)
      warningNames.push('warningOnlyMustBeEqualToAllbut')
      allButIdWithErrors = [...allButIdWithErrors, ...this.getAllButIdNotInLi()]
    }

    result = this.warningAllLineInOnlyButNoAllbut()
    if (result) {
      errorsMessage.push(result)
      warningNames.push('warningAllLineInOnlyButNoAllbut')
    }

    return {
      errors: errorsMessage,
      names: warningNames,
      allButIdWithErrors: allButIdWithErrors
    }
  }

  /**
   * @param type {string} must be one of them : 'all but', 'all', 'only'
   * @param optimizeValue {string} value from optimize field like 'only , 123, 456' or 'all' or 'all but , 123, 456'
   * @returns {Array<string | number>} with the id of the optimize field of the specified type
   */
  getIdListOfType (optimizeValue, type) {
    let prefix = ''

    if (type === ONLY) {
      prefix = 'only ,'
    } else if (type === ALLBUT) {
      prefix = 'all but ,'
    } else {
      throw Error(`'type' value must be ${ONLY} or ${ALLBUT}`)
    }

    let value = optimizeValue.replace(prefix, '').trim()
    let currentOnlysList = value.split(',')

    // string value in the trade desk
    if (this.dsp === $THETRADEDESK) {
      return currentOnlysList.map((item) => {
        return String(item).trim()
      })
    }
    // otherwise, convert to number
    return currentOnlysList.map((item) => {
      return Number(item)
    })
  }

  /**
   * @param optimizeValue {string} value from optimize field like 'only , 123, 456'
   * @returns {Array<string | number>} with the id of a optimize field of type 'only'
   */
  getTypeOnlyIdList (optimizeValue) {
    return this.getIdListOfType(optimizeValue, ONLY)
  }

  /**
   * @param optimizeValue {string} value from optimize field like 'all but , 123, 456'
   * @returns {Array<string | number>} with the id of a optimize field of type 'all but'
   */
  getTypeAllButIdList (optimizeValue) {
    return this.getIdListOfType(optimizeValue, ALLBUT)
  }

  /**
   * return a array with the instruction of type 'all' of the instructionsList
   * @param customField
   * @returns {Array<string | number>}
   */
  getTypeAllInstructions (customField = null) {
    let fieldToCheck = customField === null ? this.getRightOptimizeField() : customField
    return this.instructionsList.filter((item) => {
      return this.isOptimizeValueAll(item[fieldToCheck])
    })
  }

  /**
   * return a array with the instruction of type 'all but' of the instructionsList
   * @returns {Array<string | number>}
   */
  getTypeAllButInstructions () {
    let fieldToCheck = this.getRightOptimizeField()
    return this.instructionsList.filter((item) => {
      return this.isOptimizeValueAllBut(item[fieldToCheck])
    })
  }

  /**
   * return a array with the instruction of type 'only' of the instructionsList
   * @returns {Array}
   */
  getTypeOnlyInstructions () {
    let fieldToCheck = this.getRightOptimizeField()
    return this.instructionsList.filter((item) => {
      return this.isOptimizeValueOnly(item[fieldToCheck])
    })
  }

  /**
   * @param type {'all'|'only'|'all but'}
   * @returns {[]} the instruction of the intructionsList with the type ('only', 'all', 'all but') defined by type
   */
  getInstructionsOfType (type) {
    switch (type) {
      case ALL:
        return this.getTypeAllInstructions()
      case ONLY:
        return this.getTypeOnlyInstructions()
      case ALLBUT:
        return this.getTypeAllButInstructions()
    }
  }

  /**
   * return a array of array, each array contain the values of a line of type 'only'
   * @returns {[]}
   */
  getOnlysValue () {
    const field = this.getRightOptimizeField()
    let onlysValue = []
    const typeOnlyInstructions = this.getTypeOnlyInstructions()

    for (let i in typeOnlyInstructions) {
      let optimizeValue = typeOnlyInstructions[i][field]
      onlysValue.push(this.getTypeOnlyIdList(optimizeValue))
    }
    return onlysValue
  }

  getOptimizeValueType (optimizeValue) {
    if (this.isOptimizeValueOnly(optimizeValue)) {
      return ONLY
    } else if (this.isOptimizeValueAll(optimizeValue)) {
      return ALL
    } else if (this.isOptimizeValueAllBut(optimizeValue)) {
      return ALLBUT
    } else {
      throw Error('optimize value cant be detected')
    }
  }

  /**
   * return the 'li' field to target in function of the dsp
   * @returns {string}
   */
  getRightOptimizeField () {
    if (this.dsp === null) {
      throw Error(ERROR_MESSAGE_DSP_MUST_BE_SETTED)
    }

    return getRightOptimizeField(this.dsp)
  }

  getRightLineItemField () {
    if (this.dsp === null) {
      throw Error(ERROR_MESSAGE_DSP_MUST_BE_SETTED)
    }

    let li = ''

    if ([$YOUTUBE, $DBM, $BEESWAX, $APPNEXUS].indexOf(this.dsp) !== -1) {
      li = 'li'
    } else if ([$THETRADEDESK, $FACEBOOK].indexOf(this.dsp) !== -1) {
      li = 'ad'
    } else if (this.dsp === $MEDIAMATH) {
      li = 'str'
    } else if (this.dsp === $KAYZEN) {
      li = 'ca'
    }

    return li
  }

  /**
   * A optimizeValue is singlon when she has only 1 id (like 'only ,123', and not like 'only, 123, 456')
   * @param optimizeValue
   * @returns {boolean}
   */
  isSinglon (optimizeValue) {
    const type = this.getOptimizeValueType(optimizeValue)
    if (type === ONLY) {
      return this.getTypeOnlyIdList(optimizeValue).length <= 1
    } else if (type === ALLBUT) {
      return this.getTypeAllButIdList(optimizeValue).length <= 1
    } else {
      // here is a all, all is always singlon (no id)
      return true
    }
  }

  /**
   * @returns {boolean} is all optimize_campaigns line (appnexus) in 'all' ?
   */
  areAllOptimizeCampaignLineInAll () {
    return this.getTypeAllInstructions(OPTIMIZE_CAMPAIGNS).length === this.instructionsList.length
  }

  isOptimizeValueAllBut (optimizeValue) {
    return isOptimizeValueAllBut(optimizeValue)
  }

  isOptimizeValueOnly (optimizeValue) {
    return isOptimizeValueOnly(optimizeValue)
  }

  isOptimizeValueAll (optimizeValue) {
    return isOptimizeValueAll(optimizeValue)
  }

  /**
   * @param dsp {InstructionDsp|null}
   * @returns {OptimizeRulesChecker}
   */
  setDsp (dsp) {
    this.dsp = dsp
    return this
  }
  /**
   * @param toValid {Instruction[]}
   * @returns {OptimizeRulesChecker}
   */
  setToValid (toValid) {
    this.instructionsList = toValid.map((item) => {
      return {
        id: item.id !== undefined ? item.id : 'NC',
        [this.getRightOptimizeField()]: item[this.getRightOptimizeField()]
      }
    })
    return this
  }

  addIdToErrorMessage (msg, id = null) {
    if (id === null) {
      return `${msg} for the '${this.getRightLineItemField()}' group`
    }
    return `${msg} for the id group ${id}`
  }

  getErrorMessageOnlyOneAllAllowed (id = null) {
    if (id === null) {
      return this.addIdToErrorMessage(MESSAGE_ONLY_ONE_INTRUCTION_WHEN_ALL)
    } else {
      return `Collision between instructions with "${capitalize(this.getRightLineItemField())} =${ONLY},${id}", when "ca=all" is present, only one instruction allowed`
    }
  }

  getErrorMessageOnlyDoublon (id = null) {
    return this.addInfoToErrorMessage(MESSAGE_DOUBLON_ONLY, id)
  }

  getErrorMessageAllButIncludeOnly (id = null) {
    if (id === null) {
      return this.addIdToErrorMessage(MESSAGE_ONLY_INCLUDED_ALLBUT)
    } else {
      return `Collision between instructions with "ca=all but" and "ca=only" conditions for "${capitalize(this.getRightLineItemField())} =${ONLY},${id}`
    }
  }

  addInfoToErrorMessage (msg, id = null) {
    let toAdd = `'${ONLY}' condition at the '${this.getRightLineItemField()}' level`
    if (id !== null) {
      toAdd = `"ca=all but" and "ca=only" conditions for "${capitalize(this.getRightLineItemField())} =${ONLY},${id}"`
    }
    return `${msg}${toAdd}`
  }
}

export default OptimizeRulesChecker
