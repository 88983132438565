class UserModel {
  constructor (data) {
    this.mail = data.mail
    this.dvMail = data.dv_mail
    this.isDebugger = data.isDebugger
    this.isSettupper = data.isSettupper
    this.isAdminAndFinance = data.isAdminAndFinance
    this.isAm = data.isAm
    this.name = data.name
    this.userId = data.user_id
    this.isSale = data.isSale
  }

  getActiveMail () {
    if (this.dvMail) {
      return this.dvMail
    } else {
      return this.mail
    }
  }

  hasDvMail () {
    return this.dvMail != null && this.dvMail !== ''
  }

  getUsernameFromMail () {
    const mail = this.getActiveMail()
    if (mail) {
      return mail.substring(0, mail.indexOf('@'))
    }
    return mail
  }
}

export default UserModel
