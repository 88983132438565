import { API_LOADED, API_LOADING, apiCaller, Vue } from '../index'
import GroupKeyModel from '../../src/models/Alertings/GroupKeyModel'
import AlertModel from '@/models/Alertings/AlertModel'
import _ from 'lodash'
import { createDateAsUTC } from '../../utils/commonUtils'

function apiAlertToAlertModel (data) {
  let selected = []
  if (data.length > 0) {
    let groupKeyId = data[0].group_key.id
    for (let a of data) {
      let alert = new AlertModel(a, data[0].group_key.identity, groupKeyId)
      alert.error_type = a.group_key.error_type
      selected.push(alert)
    }
  }
  return selected
}

/**
 * @param currentFilter {CurrentFilter}
 * @param limit? {number}
 * @param offset? {number}
 * @param onlyErrorTypes? {boolean}
 * @param onlyErrorTypesNot? {boolean}
 */
async function callAlertWithCurrentFilter (currentFilter, limit, offset,
  onlyErrorTypes = null, onlyErrorTypesNot = null) {
  let mailList = currentFilter.am ? currentFilter.am : null
  let search = currentFilter.searched ? currentFilter.searched : null
  let hideDoneAndFalsePositive = !_.isNil(currentFilter.hideDoneAndFalsePositive) ? currentFilter.hideDoneAndFalsePositive : true
  let attributed = !_.isNil(currentFilter.attributed) ? currentFilter.attributed : null
  let errorType = []
  let errorTypeInput = !_.isNil(currentFilter.error_type) ? currentFilter.error_type : null
  let errorTypeCategory = !_.isNil(currentFilter.error_type_category) ? currentFilter.error_type_category : null

  if (errorTypeInput) {
    if (!Array.isArray(errorTypeInput)) {
      errorTypeInput = [errorTypeInput]
    }
    errorType = [...errorType, ...errorTypeInput]
  }

  if (errorTypeCategory) {
    errorType = [...errorType, ...errorTypeCategory]
  }

  if (errorType && !errorType.length) {
    errorType = null
  }

  let errorTypeNot = !_.isNil(currentFilter.error_type_not) ? currentFilter.error_type_not : null
  let debuggerList = currentFilter.debugger ? currentFilter.debugger : null
  let baselineType = !_.isNil(currentFilter.baseline_type) ? currentFilter.baseline_type : null
  let stratLvl = !_.isNil(currentFilter.strat_lvl) ? currentFilter.strat_lvl : null
  let optiAuto = !_.isNil(currentFilter.opti_auto) ? currentFilter.opti_auto : null

  return apiCaller.getGroupKeys(
    mailList,
    limit,
    offset,
    search,
    hideDoneAndFalsePositive,
    attributed,
    errorType,
    debuggerList,
    null,
    baselineType,
    stratLvl,
    errorTypeNot,
    onlyErrorTypes,
    onlyErrorTypesNot,
    optiAuto
  )
}

/**
 * adapt the currentFilter for the red alerts by removing some filters
 * @param currentFilter {CurrentFilter}
 * @param alertPriorityType {String}
 * @return {CurrentFilter}
 */
function generateFilterRed (currentFilter, alertPriorityType) {
  let filterRed = _.cloneDeep(currentFilter)
  filterRed.searched = null
  filterRed.error_type_not = null
  filterRed.attributed = null
  filterRed.baseline_type = null
  filterRed.clientValue = null
  filterRed.error_type_category = null
  filterRed.error_type = alertPriorityType
  filterRed.strat_lvl = null
  return filterRed
}

const state = {
  /**
   * @type {Array<GroupKeyModel>}
   */
  alerts: [],
  /**
   * @type {Array<GroupKeyModel>}
   */
  alertsPrio: [],
  /**
   * @type {CurrentFilter}
   */
  currentFilter: {
    am: [],
    clientValue: null,
    advertiserId: null,
    status: null,
    searched: null,
    hideDoneAndFalsePositive: true,
    attributed: null,
    error_type: null,
    debugger: null,
    baseline_type: null,
    strat_lvl: null,
    error_type_not: null,
    error_type_category: null,
    opti_auto: null
  },
  excludedDefaultAm: ['rémi', 'julien', 'quentin'],
  /**
   * @type {StatusAction[]}
   */
  statusAction: [
    { action: 'done', icon: 'check', text: 'Set as done', done_text: 'Set as done', remove_text: 'Uncheck done' },
    { action: 'false_positive', icon: 'close', text: 'Set as useless', done_text: 'Set as false positive', remove_text: 'Uncheck false positive' },
    { action: 'help', icon: 'help_outline', text: 'Need help', done_text: 'Set as need help', remove_text: 'Uncheck help' },
    { action: 'true_positive', icon: 'replay', text: 'Uncheck false positive (true positive)', done_text: 'Set as true positive', remove_text: 'Uncheck true positive' }
  ],
  /**
   * @type {StatusAction[]}
   */
  statusActionDebugger: [
    {
      action: 'fixed',
      icon: 'build',
      text: 'Set as fixed',
      done_text: async (alerts) => {
        let by = null
        if (Array.isArray(alerts)) {
          let response = await apiCaller.getAlertsPerGroupKey(alerts[0].keyFrom, false)
          if (apiCaller.isResponseError(response)) {
            by = 'Can not find the fixer'
          } else {
            by = response.data.find(item => item.fixed_message && item.fixed_message.message_by).fixed_message.message_by
          }
        } else {
          // alerts is not a array, we can access the property directly
          by = alerts.fixed_message.message_by
        }
        return `Fixed by ${by}`
      },
      remove_text: 'Uncheck fixed'
    },
    {
      action: 'attributed',
      icon: 'face',
      text: 'Attribute to ...',
      done_text: async (alerts) => {
        let to = null
        if (Array.isArray(alerts)) {
          let response = await apiCaller.getAlertsPerGroupKey(alerts[0].keyFrom, false)
          if (apiCaller.isResponseError(response)) {
            to = 'Can not find'
          } else {
            to = response.data.find(item => item.attributed_message && item.attributed_message.message_to).attributed_message.message_to
          }
        } else {
          to = alerts.attributed_message.message_to
        }

        return to ? `Attributed to ${to}` : 'Attributed'
      },
      remove_text: 'Unattribute'
    }
  ],
  apiStatus: API_LOADED,
  /**
   * the date timestamp of the last call to the api
   */
  lastUpdate: null,
  /**
   * fill this array with eventListener already started in the alertings part for stocking the informations
   * and avoid to call the event twice by checking this value
   */
  alertingsListener: [],
  /**
   * contain the alerts summary used in 'False positive / Help Summary'
   */
  summary: {
    error_type: [],
    users_summary: []
  },
  /**
   * info about which autocomplete is selected in alertFilter
   * false : mode am
   * true : mode debugger
   */
  switchDebugger: false,
  /**
   * contain the list of checked alert id to update with 'action'
   */
  bucketsAlertsId: [],
  numberOfLineSelectedBuckets: 0,
  /**
   * contain the data loaded when the user click on 'load more'.
   * The key is the hashed key of the groupKey
   */
  loadedData: {},
  /**
   * contain the alerts of the selected alerts
   * @type Record<string, AlertModel[]>
   */
  selectedData: {},
  endSliceAlerts: 0,
  baseLimitAlertings: 100,
  noMoreData: false,
  /**
   * @type {Date}
   */
  lastCheckNewAlert: null,
  /**
   * @type {Array<GroupKeyModel>}
   */
  newAlerts: [],
  alertPriorityType: ['1.1.1', '1.1.2']
}

const actions = {
  async getAlertsData (context, payload) {
    context.commit('setApiIsLoading')

    context.commit('saveLastUpdate')
    let currentFilter = context.getters.getCurrentFilterAlert
    let filterNotRed = _.cloneDeep(currentFilter)
    // if a error_type is used in filter, we remove all error_type_not from filterNotRed
    if (currentFilter.error_type || (currentFilter.error_type_category && currentFilter.error_type_category.length)) {
      filterNotRed.error_type_not = null
    } else {
      filterNotRed.error_type_not = context.state.alertPriorityType
    }
    let filterRed = generateFilterRed(currentFilter, context.state.alertPriorityType)

    let limit = context.getters.getBaseLimitAlertings
    let offset = context.getters.getEndSliceAlert
    const [ response, responsePrio ] = await Promise.all([
      callAlertWithCurrentFilter(filterNotRed, limit, offset, null, true),
      callAlertWithCurrentFilter(filterRed, 100, 0, true, null)
    ])

    if (!apiCaller.isCancelError(response)) {
      // for manage the behaviour when cookie are enabled and Jwt is here but expired
      if (apiCaller.isApiResponseStatusUnauthorized(response)) {
        context.commit('setAskRelog', true)
      }
      if (response.data.length || offset === 0) {
        context.commit('loadAlertsWithLimitAndOffset', { data: response.data, limit: limit, offset: offset })
      } else {
        context.commit('setNoMoreData', true)
        console.warn('No more data for this search')
      }

      if (payload && payload.firstTime && payload.currentUser && payload.userStatus) {
        context.commit('setDefaultAmCurrentFilter', { currentUser: payload.currentUser, userStatus: payload.userStatus })
      }
    }

    if (!apiCaller.isCancelError(responsePrio)) {
      if (responsePrio.data && Array.isArray(responsePrio.data)) {
        context.commit('loadAlertsPrio', responsePrio.data)
      }
    }

    context.commit('setApiIsLoaded')
  },
  async callCheckNewAlerts (context, payload) {
    const mailList = [payload.currentUserMail]
    let now = new Date()
    let lastCheck = context.getters.getLastCheckNewAlert

    if (!(lastCheck instanceof Date)) {
      lastCheck = new Date(lastCheck)
    }
    // get the last date check, if not last date check, get the current date and remove payload.minutes
    const lastDate = lastCheck || now.setMinutes(now.getMinutes() - payload.minutes)
    const dateSend = new Date()
    const response = await apiCaller.getGroupKeys(
      mailList, 100, 0, null, true,
      null, null, null, lastDate)
    if (apiCaller.isResponseError(response)) {
      console.warn('[alertings.js] Error when calling the api check new Alerts')
    } else {
      context.commit('loadNewAlerts', response.data.filter(item => item.group_key_list.find(item => {
        let dateComp = createDateAsUTC((new Date(item.first_insertion_date)))
        return dateComp >= lastDate
      })))
      context.commit('setLastCheckNewAlert', dateSend)
    }
  },
  bucketsUpdateAlert (context, payload) {
    return apiCaller.updateStatusGroupKey(
      payload.groupKeyIds,
      payload.status,
      payload.to,
      payload.content,
      payload.remove
    )
  },
  async getSummaryData (context) {
    let response = await apiCaller.getSummary()
    if (apiCaller.isApiResponseStatusUnauthorized(response)) {
      context.commit('setAskRelog', true)
    }
    context.commit('setSummary', response.data)
  },
  async getAlertsOfGroupKeyData (context, payload) {
    return apiCaller.getAlertsPerGroupKey(payload.groupKeyId, payload.expired)
  },
  getAlertsIndexData (context) {
    return apiCaller.getAlertsIndex()
  },
  /**
   * @param context
   * @param alertModel {AlertModel}
   * @returns {Promise<object>}
   */
  async getCheckPreMepDataOfAlerts (context, alertModel) {
    return apiCaller.getCheckPreMepResult(alertModel.group_key.insertion_order_id, alertModel.group_key.dsp)
  }
}

const mutations = {
  initializeStore (state, payload) {
    console.warn('Initialize store')
    let r = localStorage.getItem('lastCheckNewAlert')
    if (r) {
      if (isNaN(r)) {
        state.lastCheckNewAlert = new Date(r)
      } else {
        state.lastCheckNewAlert = new Date(Number(r))
      }
    }
  },
  loadAlerts (state, payload) {
    let groupKeyModels = []

    for (let i in payload) {
      groupKeyModels[i] = new GroupKeyModel(payload[i])
    }
    if (state.endSliceAlerts !== 0) {
      Vue.set(state, 'alerts', [...state.alerts, ...groupKeyModels])
    } else {
      Vue.set(state, 'alerts', groupKeyModels)
    }
  },
  loadAlertsWithLimitAndOffset (state, payload) {
    let limit = payload.limit
    let offset = payload.offset
    let data = payload.data
    let groupKeyModels = []

    for (let i in data) {
      groupKeyModels[i] = new GroupKeyModel(data[i])
    }
    if (offset === 0) {
      Vue.set(state, 'alerts', groupKeyModels)
    } else {
      let start = offset
      let end = limit + offset
      Vue.set(state, 'alerts', [...state.alerts.slice(0, start), ...groupKeyModels, ...state.alerts.slice(end)])
    }
  },
  loadAlertsPrio (state, data) {
    let groupKeyModels = []
    for (let i in data) {
      groupKeyModels[i] = new GroupKeyModel(data[i])
    }
    Vue.set(state, 'alertsPrio', groupKeyModels)
  },
  updateAlertFilter (state, payload) {
    if (payload !== null && payload !== undefined) {
      Vue.set(state, 'currentFilter', payload)
    }
  },
  setApiIsLoaded (state) {
    Vue.set(state, 'apiStatus', API_LOADED)
  },
  setApiIsLoading (state) {
    Vue.set(state, 'apiStatus', API_LOADING)
  },
  setDefaultAmCurrentFilter (state, payload) {
    if (state.excludedDefaultAm.indexOf(payload.currentUser) === -1 && payload.userStatus.isAm) {
      const withoutDuplicate = new Set([...state.currentFilter.am, payload.currentUser])
      Vue.set(state.currentFilter, 'am', [...withoutDuplicate])
    }
  },
  resetCurrentFilterAlerts (state) {
    Vue.set(state, 'currentFilter', {
      am: [],
      clientValue: null,
      advertiserId: null,
      status: null,
      searched: null,
      attributed: null,
      hideDoneAndFalsePositive: null,
      debugger: null,
      error_type: null,
      baseline_type: null,
      strat_lvl: null
    })
  },
  saveLastUpdate (state) {
    state.lastUpdate = (new Date()).getTime()
  },
  addAlertingsListener (state, newEvent) {
    state.alertingsListener.push(newEvent)
  },
  removeAlertingsListener (state, eventToRemove) {
    if (state.alertingsListener.indexOf(eventToRemove) !== -1) {
      delete state.alertingsListener[eventToRemove]
    }
  },
  setSummary (state, payload) {
    Vue.set(state, 'summary', payload)
  },
  setSwitchDebugger (state, payload) {
    Vue.set(state, 'switchDebugger', payload)
  },
  /**
   * each time this function is called, the state.numberOfLineSelectedBuckets is incremented
   * @param state
   * @param ids
   */
  addIdsToBucket (state, ids) {
    for (let i in ids) {
      if (state.bucketsAlertsId.indexOf(ids[i]) === -1) {
        state.bucketsAlertsId.push(ids[i])
      }
    }
    state.numberOfLineSelectedBuckets++
  },
  /**
   * each time this function is called, the state.numberOfLineSelectedBuckets is decremented
   * @param state
   * @param ids
   */
  removeIdsFromBucket (state, ids) {
    for (let i in ids) {
      let index = state.bucketsAlertsId.indexOf(ids[i])
      if (index !== -1) {
        state.bucketsAlertsId.splice(index, 1)
      }
    }
    state.numberOfLineSelectedBuckets--
  },
  resetBucketAlertsId (state) {
    Vue.set(state, 'bucketsAlertsId', [])
    state.numberOfLineSelectedBuckets = 0
  },
  setLoadedData (state, data) {
    let selected = apiAlertToAlertModel(data)
    if (selected.length) {
      let groupKeyId = data[0].group_key.id
      Vue.set(state.loadedData, groupKeyId, selected)
    }
  },
  setSelectedData (state, data) {
    let selected = apiAlertToAlertModel(data)
    if (selected.length) {
      let groupKeyId = data[0].group_key.id
      Vue.set(state.selectedData, groupKeyId, selected)
    }
  },
  setEndSliceAlerts (state, endSlice) {
    Vue.set(state, 'endSliceAlerts', endSlice)
  },
  resetEndSlice (state) {
    Vue.set(state, 'endSliceAlerts', 0)
  },
  setNoLimitEndSlice (state) {
    Vue.set(state, 'endSliceAlerts', 1000)
  },
  setNoMoreData (state, value) {
    Vue.set(state, 'noMoreData', value)
  },
  loadNewAlerts (state, data) {
    let groupKeyModels = []
    let allIdentityIds = state.newAlerts.map(item => item.id)
    for (let i in data) {
      if (!allIdentityIds.includes(data[i].id)) {
        groupKeyModels[i] = new GroupKeyModel(data[i])
      }
    }
    Vue.set(state, 'newAlerts', [...state.newAlerts, ...groupKeyModels])
  },
  setLoadNewAlerts (state, data) {
    Vue.set(state, 'newAlerts', data)
  },
  setLastCheckNewAlert (state, value) {
    Vue.set(state, 'lastCheckNewAlert', value)
    localStorage.setItem('lastCheckNewAlert', value.getTime())
  }
}

const getters = {
  getAlerts: state => {
    return state.alerts
  },
  getAlertsPrio: state => {
    return state.alertsPrio
  },
  getCurrentFilterAlert: state => {
    return state.currentFilter
  },
  getApiStatus: state => {
    return state.apiStatus
  },
  isApiLoaded: state => {
    return state.apiStatus === API_LOADED
  },
  getLastUpdate: state => {
    return state.lastUpdate
  },
  getAlertingsListener: state => {
    return state.alertingsListener
  },
  getSummary: state => {
    return state.summary
  },
  getSwitchDebugger: state => {
    return state.switchDebugger
  },
  getBucketsAlertsId: state => {
    return state.bucketsAlertsId
  },
  getNumberOfLineSelectedBuckets: state => {
    return state.numberOfLineSelectedBuckets
  },
  getLoadedData: state => {
    return state.loadedData
  },
  getSelectedData: state => {
    return state.selectedData
  },
  getStatusActionNormal: state => {
    return state.statusAction
  },
  getStatusActionDebugger: state => {
    return [...state.statusAction, ...state.statusActionDebugger]
  },
  getStatusAction (state, getters) {
    let statusList = getters.getStatusActionNormal

    if (getters.isUserDebugger) {
      statusList = getters.getStatusActionDebugger
    }
    return statusList
  },
  getEndSliceAlert (state) {
    return state.endSliceAlerts
  },
  getBaseLimitAlertings (state) {
    return state.baseLimitAlertings
  },
  getNoMoreData (state) {
    return state.noMoreData
  },
  getNewAlerts (state) {
    return state.newAlerts
  },
  getLastCheckNewAlert (state) {
    return state.lastCheckNewAlert
  }
}

export const alertings = {
  actions,
  mutations,
  state,
  getters
}
