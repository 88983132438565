import axios from 'axios'

export function getBearer (jwt: string): string {
  return `Bearer ${jwt}`
}

export function getAxiosHeadersJson (jwt: string): object {
  return {
    Authorization: getBearer(jwt),
    'Content-Type': 'application/json'
  }
}

export function getAxiosHeadersFormData (jwt: string): object {
  return {
    Authorization: getBearer(jwt),
    'Content-Type': 'multipart/form-data'
  }
}

export function getAxiosHeaders (jwt: string): object {
  return {
    Authorization: getBearer(jwt)
  }
}

export function getAxiosJwtInstance (jwt: string, jsonContent = false, formData = false) {
  if (jsonContent) {
    return axios.create({
      headers: getAxiosHeadersJson(jwt)
    })
  } else if (formData) {
    return axios.create({
      headers: getAxiosHeadersFormData(jwt)
    })
  }

  return axios.create({
    headers: getAxiosHeaders(jwt)
  })
}
