
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
@Component({})
export default class Usersnap extends Vue {
  @Prop()
  authenticated : boolean

  usersnapApiKey : string = null
  usersnapGlobalApiKey : string = null

  logged: boolean = false

  async init () {
    await this.getCredentials()
    const apikey = this.usersnapApiKey
    window.onUsersnapCXLoad = function (api: any) {
      api.init()
      api.show(apikey)
    }
    const script = document.createElement('script')
    script.defer = true
    script.src = `https://widget.usersnap.com/global/load/${this.usersnapGlobalApiKey}?onload=onUsersnapCXLoad`
    document.getElementsByTagName('head')[0].appendChild(script)
  }
  async getCredentials () {
    const result = await this.$apiCaller.getUsersnapCredentials()

    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error while getting usersnap credentials')
      return
    }

    const keys = result.data
    this.usersnapApiKey = keys.usersnapApiKey
    this.usersnapGlobalApiKey = keys.usersnapGlobalApiKey
  }

  @Watch('authenticated')
  async runUserSnap (authenticated: boolean) {
    if (authenticated) {
      await this.init()
    }
  }
}
