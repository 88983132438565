import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

// TODO pass Vuetify to the new version (2.0) => https://github.com/vuetifyjs/vuetify/releases#user-content-upgrade-guide
Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    light: true
  },
  icons: {
    iconfont: 'mdi'
  }
})
