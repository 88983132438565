import { $THETRADEDESK } from './dspConfig'
import { roundNum } from '../utils/commonUtils'

export function getDataUnderdeliveryOverdelivery (data, overdelivery = false) {
  let percentProp = overdelivery ? 'max_overdelivery' : 'max_underdelivery'
  return {
    forThePastXDays: data.max_nb_days !== undefined ? data.max_nb_days : 'NC',
    concernedLvl: data.concerned_lvl !== undefined ? data.concerned_lvl : 'NC',
    percent: data[percentProp] !== undefined ? data[percentProp] : 'NC'
  }
}

export function getLevelSentenceIOForAlertDescription (concernedLvl) {
  let level = 'NC'
  let lvl = getConcernedLevel(concernedLvl)
  if (lvl === 'io') {
    level = '<strong>This IO</strong> has'
  } else if (lvl === 'li') {
    level = '<strong>Some line items</strong> have'
  }
  return level
}

const LVL_IO_KEYS = ['the IO', 'the CP', 'the CPG']
const LVL_LI_KEYS = ['some line items', 'some line_items', 'some ad_groups', 'some strategies']
/***
 * convert concernedLvl from the alert_data to a simple io or li
 * @param concernedLvl
 * @return {'io'|'li'}
 */
export function getConcernedLevel (concernedLvl) {
  if (LVL_IO_KEYS.includes(concernedLvl)) {
    return 'io'
  } else if (LVL_LI_KEYS.includes(concernedLvl)) {
    return 'li'
  }
  return 'NC'
}

export function getLevel2SentenceIOForAlertDescription (concernedLvl) {
  let level = 'NC'
  if (LVL_IO_KEYS.includes(concernedLvl)) {
    level = '<strong>this IO</strong>'
  } else if (LVL_LI_KEYS.includes(concernedLvl)) {
    level = '<strong>these line items</strong>'
  }
  return level
}

/**
 * return mainData
 * for underdelivery and underdelivery end of billing
 * @param data
 * @param settings
 * @returns {string}
 */
export function createMainDataUnderdelivery (data, settings) {
  const { forThePastXDays, concernedLvl, percent } = getDataUnderdeliveryOverdelivery(data)
  return `Underdelivery situation during the past <strong>${forThePastXDays}</strong> day(s).
          Up to <strong>${percent}</strong> underlivery situation yesterday for <strong>${concernedLvl}</strong>`
}

/**
 * return mainData
 * for FuckFuckFuckFuck and Fuck^2
 * @param data
 * @param settings
 * @param groupKey
 * @returns {string}
 */
export function createMainDataFuckAlerts (data, settings, groupKey) {
  if (groupKey.dsp.toLowerCase() === $THETRADEDESK) {
    return `Negative margin of <strong>${roundNum(data.real_profit)} USD</strong> during past hour.
            All adgroups of the campaign have been de-activated. See "details" for a complete list.`
  }
  return `Negative margin of <strong>${roundNum(data.real_profit)} USD</strong> during current
          ${settings.lookup_period}. IO has been <strong>de-activated</strong>`
}
